import axiosService from "services/axios.service";
import { showErrorMessage, showSuccessMessage, } from "./notification";


//initial values
const Initial_State = {
    backups: [],
    backupFiles: [],
    loading: false,
    error: false,

};

// Action types
const GET_BACKUPS = "GET_BACKUPS";
const GET_BACKUP_FILES = "GET_BACKUP_FILES";
const LOADING = "LOADING";
const SAVE_BACKUP_LOADING = "SAVE_BACKUP_LOADING";
const UPLOAD_BACKUP_LOADING = "UPLOAD_BACKUP_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case GET_BACKUPS:
            return {
                ...state,
                error: null,
                backups: action?.payload?.data,
            };
        case GET_BACKUP_FILES:
            return {
                ...state,
                error: null,
                backupFiles: action?.payload?.data,
            };
        case LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case SAVE_BACKUP_LOADING:
            return {
                ...state,
                saveBackupLoading: action.payload,
                error: null,
            };
        case UPLOAD_BACKUP_LOADING:
            return {
                ...state,
                uploadLoading: action.payload,
                error: null,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: LOADING,
        payload: data,
    };
}

export function isSaveBackupLoading(data) {
    return {
        type: SAVE_BACKUP_LOADING,
        payload: data,
    };
}

export function isUploadBackupLoading(data) {
    return {
        type: UPLOAD_BACKUP_LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Load Backup Files
export function onLoadBackupFiles(data) {
    return {
        type: GET_BACKUP_FILES,
        payload: data,
    };
}

// Load Backups
export function onLoadBackups(data) {
    return {
        type: GET_BACKUPS,
        payload: data,
    };
}



// Actions
export function getBackups() {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.BackUp.getAllBackUp().then(
            (response) => {
                dispatch(onLoadBackups(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load backups",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}

export function getBackupFiles() {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.BackUp.getAllBackedUpFiles().then(
            (response) => {
                dispatch(onLoadBackupFiles(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load backup files",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}


// Run Backup Immediately
export function runBackupImmediately(setIsModalOpened) {
    return (dispatch) => {
        dispatch(isSaveBackupLoading(true));
        return axiosService.BackUp.runBackupImmediately().then(
            (response) => {
                // Close Modal
                setIsModalOpened(false)
                dispatch(isSaveBackupLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Backup initiate immediately!",
                        title: "Success",
                    })
                );
                dispatch(getBackupFiles(20, 1));
                dispatch(getBackups())
            },
            (error) => {
                // Close Modal
                setIsModalOpened(false)
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to initiate backup!",
                    })
                );
                dispatch(isSaveBackupLoading(false));
            }
        );
    };
}

// Schedule Backup
export function scheduleBackup(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isSaveBackupLoading(true));
        return axiosService.BackUp.scheduleBackup(data,).then(
            (response) => {
                // Close Modal
                setIsModalOpened(false)
                dispatch(isSaveBackupLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Backup scheduled successfully!",
                        title: "Success",
                    })
                );
                dispatch(getBackupFiles(20, 1));
                dispatch(getBackups())
            },
            (error) => {
                // Close Modal
                setIsModalOpened(false)
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to schedule backup!",
                    })
                );
                dispatch(isSaveBackupLoading(false));
            }
        );
    };
}

// Delete Backup
export function deleteBackup(backupId) {
    return (dispatch) => {
        dispatch(isSaveBackupLoading(true));
        return axiosService.BackUp.deleteBackup(backupId).then(
            (response) => {
                dispatch(isSaveBackupLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Backup deleted!",
                        title: "Success",
                    })
                );
                dispatch(getBackupFiles(20, 1));
                dispatch(getBackups())
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to delete backup!",
                    })
                );
                dispatch(isSaveBackupLoading(false));
            }
        );
    };
}

// Delete Backup Files
export function deleteBackedUpFile(backupId) {
    return (dispatch) => {
        // dispatch(isSaveBackupLoading(true));
        return axiosService.BackUp.deleteBackedUpFile(backupId).then(
            (response) => {
                // dispatch(isSaveBackupLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Backup file deleted!",
                        title: "Success",
                    })
                );
                dispatch(getBackupFiles(20, 1));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to delete backup file!",
                    })
                );
                // dispatch(isSaveBackupLoading(false));
            }
        );
    };
}

// Download Backup Files
export function downloadBackedUpFile(backupId) {
    return (dispatch) => {
        // dispatch(isSaveBackupLoading(true));
        return axiosService.BackUp.downloadBackedUpFile(backupId).then(
            (response) => {
                // dispatch(isSaveBackupLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Backup file downloaded!",
                        title: "Success",
                    })
                );
                dispatch(getBackupFiles(20, 1));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to download backup file!",
                    })
                );
                // dispatch(isSaveBackupLoading(false));
            }
        );
    };
}

// Upload Backup
export function uploadBackup(data) {
    return (dispatch) => {
        dispatch(isUploadBackupLoading(true));
        return axiosService.BackUp.uploadBackup(data).then(
            (response) => {
                dispatch(isUploadBackupLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Backup uploaded!",
                        title: "Success",
                    })
                );
                dispatch(getBackupFiles(20, 1));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to upload backup file!",
                    })
                );
                dispatch(isUploadBackupLoading(false));
            }
        );
    };
}

// Restore Backup
export function restoreBackup(data) {
    return (dispatch) => {
        // dispatch(isSaveBackupLoading(true));
        return axiosService.BackUp.restoreBackup(data).then(
            (response) => {
                // dispatch(isSaveBackupLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Backup restored!",
                        title: "Success",
                    })
                );
                dispatch(getBackupFiles(20, 1));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to restore backup file!",
                    })
                );
                // dispatch(isSaveBackupLoading(false));
            }
        );
    };
}