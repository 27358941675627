import { showErrorMessage } from "./notification";
import axiosService from "services/axios.service";

// initial values
const Initial_State = {
  categories: {},
  loading: false,
  error: false,
};

// Action types
const LOAD_CATEGORIES = "LOAD_CATEGORIES";
const LOADING = "LOADING";
const ERROR = "ERROR";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case LOAD_CATEGORIES:
      return {
        ...state,
        error: null,
        loading: false,
        categories: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

export function onLoadCategories(data) {
  return {
    type: LOAD_CATEGORIES,
    payload: data,
  };
}

export function isRequestLoading(data) {
  return {
    type: LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}

export function loadCategories() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Category.load().then(
      (response) => {
        //handle success
        dispatch(onLoadCategories(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load categories",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}
