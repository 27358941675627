import React, { createContext, useState } from "react";

export const DisableUploadFileContext = createContext(null);
function DisableUploadFileContextProvider({ children }) {
  const [disableUploadFileButton, setDisableUploadFileButton] = useState(false);

  return (
    <DisableUploadFileContext.Provider
      value={{ disableUploadFileButton, setDisableUploadFileButton }}
    >
      {children}
    </DisableUploadFileContext.Provider>
  );
}

export default DisableUploadFileContextProvider;
