import axiosService from "services/axios.service";
import { showErrorMessage, showSuccessMessage, } from "./notification";


//initial values
const Initial_State = {
    allGroups: [],
    loading: false,
    error: false,

};

// Action types
const GROUPS = "GROUPS";
const LOADING = "LOADING";
const CREATE_LOADING = "CREATE_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case GROUPS:
            return {
                ...state,
                error: null,
                loading: false,
                allGroups: action?.payload?.data?.data,
            };
        case LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case CREATE_LOADING:
            return {
                ...state,
                createLoading: action.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: LOADING,
        payload: data,
    };
}

export function isCreateLoading(data) {
    return {
        type: CREATE_LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Filter Response
export function onGroupDataLoad(data) {
    return {
        type: GROUPS,
        payload: data,
    };
}


// Actions
export function getAllGroups() {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Group.loadGroups().then(
            (response) => {
                dispatch(onGroupDataLoad(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load groups",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}


export function createGroup(data) {
    return (dispatch) => {
        dispatch(isCreateLoading(true));
        return axiosService.Group.createGroup(data).then(
            (response) => {
                dispatch(isCreateLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Group created successfully",
                        title: "Success",
                    })
                );
                dispatch(getAllGroups());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to create group",
                    })
                );
                dispatch(isCreateLoading(false));
            }
        );
    };
}

export function editGroup(groupId, data) {
    return (dispatch) => {
        dispatch(isCreateLoading(true));
        return axiosService.Group.editGroup(groupId, data).then(
            (response) => {
                dispatch(isCreateLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Group editted successfully",
                        title: "Success",
                    })
                );
                dispatch(getAllGroups());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to edit group",
                    })
                );
                dispatch(isCreateLoading(false));
            }
        );
    };
}

export function deleteGroup(groupId, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isCreateLoading(true));
        return axiosService.Group.deleteGroup(groupId).then(
            (response) => {
                dispatch(isCreateLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Group deleted successfully",
                        title: "Success",
                    })
                );
                dispatch(getAllGroups());
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to delete group",
                    })
                );
                dispatch(isCreateLoading(false));
            }
        );
    };
}