import axiosService from "services/axios.service";
import { showErrorMessage, } from "./notification";


//initial values
const Initial_State = {
    filterData: {},
    filterAttribute: {},
    filterLoading: false,
    filterAttributeLoading: false,
    error: false,

};

// Action types
const FILTER_DATA = "FILTER_DATA";
const FILTER_ATTRIBUTE = "FILTER_DAATTRIBUTE";
const FILTER_LOADING = "FILTER_LOADING";
const FILTER_ATTRIBUTES_LOADING = "FILTER_ATTRIBUTES_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case FILTER_DATA:
            return {
                ...state,
                error: null,
                filterLoading: false,
                filterData: action?.payload?.data?.data,
            };
        case FILTER_ATTRIBUTE:
            return {
                ...state,
                error: null,
                filterAttributeLoading: false,
                filterAttribute: action?.payload?.data?.data,
            };
        case FILTER_LOADING:
            return {
                ...state,
                filterLoading: action.payload,
                error: null,
            };
        case FILTER_ATTRIBUTES_LOADING:
            return {
                ...state,
                filterAttributeLoading: action.payload,
                error: null,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: FILTER_LOADING,
        payload: data,
    };
}
export function isFilterAttributeLoading(data) {
    return {
        type: FILTER_ATTRIBUTES_LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Filter Attribute
export function onFilterAttributeLoad(data) {
    return {
        type: FILTER_ATTRIBUTE,
        payload: data,
    };
}

// Filter Response
export function onFilterDataLoad(data) {
    return {
        type: FILTER_DATA,
        payload: data,
    };
}


// Actions
export function getFilterAttribute() {
    return (dispatch) => {
        dispatch(isFilterAttributeLoading(true));
        return axiosService.Filter.getFilterAttribute().then(
            (response) => {
                dispatch(onFilterAttributeLoad(response));
                dispatch(isFilterAttributeLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load filter attribute",
                    })
                );
                dispatch(isFilterAttributeLoading(false));
            }
        );
    };
}

export function loadFilterData(data, quantity = 20, page = 1) {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Filter.loadFilterData(data, quantity, page).then(
            (response) => {
                dispatch(onFilterDataLoad(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load filter data",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}