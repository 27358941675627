import axiosService from "services/axios.service";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";



//initial values
const Initial_State = {
    licenseManagementData: {},
    loading: false,
    error: false,

};

// Action types
const LICENSE_MANAGEMENT = "LICENSE_MANAGEMENT";
const LICENSE_MANAGEMENT_LOADING = "LOADING";
const SAVE_LICENSE_MANAGEMENT_LOADING = "SAVE_LICENSE_MANAGEMENT_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case LICENSE_MANAGEMENT:
            return {
                ...state,
                error: null,
                loading: false,
                licenseManagementData: action?.payload?.data,
            };
        case LICENSE_MANAGEMENT_LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case SAVE_LICENSE_MANAGEMENT_LOADING:
            return {
                ...state,
                saveLoading: action.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(constant, data) {
    return {
        type: constant,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

export function onLicenseManagementLoad(data) {
    return {
        type: LICENSE_MANAGEMENT,
        payload: data,
    };
}


// Actions
export function getLicenseManagementData() {
    return (dispatch) => {
        dispatch(isRequestLoading(LICENSE_MANAGEMENT_LOADING, true));
        return axiosService.LicenseManagement.getLicenseManagementData().then(
            (response) => {
                dispatch(onLicenseManagementLoad(response));
                dispatch(isRequestLoading(LICENSE_MANAGEMENT_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, "Failed to load license management data")
                dispatch(isRequestLoading(LICENSE_MANAGEMENT_LOADING, false));
            }
        );
    };
}


// Update License Management Data
export function updateLicenseManagement(data) {
    return (dispatch) => {
        dispatch(isRequestLoading(SAVE_LICENSE_MANAGEMENT_LOADING, true));
        return axiosService.LicenseManagement.updateLicenseManagement(data).then(
            (response) => {
                dispatch(isRequestLoading(SAVE_LICENSE_MANAGEMENT_LOADING, false));
                handleThrowSuccess(dispatch, "License management data updated successfully!")
                dispatch(getLicenseManagementData());
            },
            (error) => {
                handleThrowError(dispatch, "Failed to update license management data!")
                dispatch(isRequestLoading(SAVE_LICENSE_MANAGEMENT_LOADING, false));
            }
        );
    };
}
