import axiosService from "services/axios.service";
import { showErrorMessage, showSuccessMessage } from "./notification";
import { MESSAGE_TYPE } from "store/constant";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";

//initial values
const Initial_State = {
    users: [],
    loggedInUsers: [],
    loading: false,
    isCreateLoading: false,
    error: false,
}

// Action types
const USERS = "USERS";
const ALL_USERS = "ALL_USERS";
const LOGGED_IN_USERS = "LOGGED_IN_USERS";
const LOADING = "LOADING";
const IS_CREATING_LOADING = "IS_CREATING_LOADING";
const ERROR = "ERROR";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case USERS:
            return {
                ...state,
                error: null,
                loading: false,
                users: action?.payload?.data?.users
            };
        case ALL_USERS:
            return {
                ...state,
                allUsers: action?.payload?.data?.users,
            };
        case LOGGED_IN_USERS:
            return {
                ...state,
                error: null,
                loading: false,
                loggedInUsers: action?.payload?.data?.data,
            };
        case LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case IS_CREATING_LOADING:
            return {
                ...state,
                isCreateLoading: action.payload,
                error: null,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(constant, data) {
    return {
        type: constant,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

export function OnLoadUsers(data) {
    return {
        type: USERS,
        payload: data,
    };
}

export function OnGetAllUsers(data) {
    return {
        type: ALL_USERS,
        payload: data,
    };
}

export function OnLoadLoggedInUsers(data) {
    return {
        type: LOGGED_IN_USERS,
        payload: data,
    };
}

// Actions
export function loadUsers() {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.loadUsers().then(
            (response) => {
                dispatch(OnLoadUsers(response));
                dispatch(isRequestLoading(LOADING, false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load users",
                    })
                );
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}


// ADMIN ADMIN ADMIN
export function getAllUsers(quantity = 20, page = 1) {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.getAllUsers(quantity, page).then(
            (response) => {
                dispatch(OnGetAllUsers(response));
                dispatch(isRequestLoading(LOADING, false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load users",
                    })
                );
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}


export function loadLoggedInUsers() {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.loadLoggedInUsers().then(
            (response) => {
                dispatch(OnLoadLoggedInUsers(response));
                dispatch(isRequestLoading(LOADING, false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load users",
                    })
                );
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}


// Create User
export function createUser(data) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.User.createUser(data).then(
            (response) => {
                // Refetch all users
                dispatch(getAllUsers())
                // Throw Success Message
                handleThrowSuccess(dispatch, "New user created successfully!")
                // Set Loading to False
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
            },
            (error) => {
                // Throw Error Message
                handleThrowError(dispatch, error || "Failed to create user!")
                //   Set Loading to False
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}

// Create Many Users
export function createManyUsers(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.User.createManyUsers(data).then(
            (response) => {
                // Refetch all users
                dispatch(getAllUsers())
                // Throw Success Message
                handleThrowSuccess(dispatch, "Users imported successfully!")
                // Set Loading to False
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close Modal
                setIsModalOpened(false)
            },
            (error) => {
                // Throw Error Message
                handleThrowError(dispatch, error)
                //   Set Loading to False
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}

// Import Users
export function importUsers(data) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.User.importUsers(data).then(
            (response) => {
                // Refetch all users
                dispatch(getAllUsers())
                // Throw Success Message
                handleThrowSuccess(dispatch, "Users imported successfully!")
                // Set Loading to False
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
            },
            (error) => {
                // Throw Error Message
                handleThrowError(dispatch, error || "Failed to import users!")
                //    Set Loading to False
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}

// Edit User
export function editUser(userId, data, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.editUser(userId, data).then(
            (response) => {
                // Refetch logged in users to update user info
                dispatch(loadLoggedInUsers())
                dispatch(getAllUsers());
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "User details editted successfully!",
                        title: "Success",
                    })
                );
                dispatch(isRequestLoading(LOADING, false));
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                // Throw Error Message
                handleThrowError(dispatch, error || "Failed to edit user!")
                //    Set Loading to False
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}

// Activate User
export function activateUser(data, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.activateUser(data).then(
            (response) => {
                // Refetch logged in users to update user info
                dispatch(loadLoggedInUsers())
                dispatch(getAllUsers())
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "User account has been activated!",
                        title: "Success",
                    })
                );
                dispatch(isRequestLoading(LOADING, false));
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to activate user account!",
                    })
                );
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}

// Deactivate User
export function deactivateUser(data, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.deactivateUser(data).then(
            (response) => {
                // Refetch logged in users to update user info
                dispatch(loadLoggedInUsers())
                dispatch(getAllUsers());
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "User account has been deactivated!",
                        title: "Success",
                    })
                );
                dispatch(isRequestLoading(LOADING, false));
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to deactivated user account!",
                    })
                );
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}

// Delete User
export function deleteUser(data, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.deleteUser(data).then(
            (response) => {
                // Refetch all users to update user info
                dispatch(getAllUsers());
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "User account has been deleted!",
                        title: "Success",
                    })
                );
                dispatch(isRequestLoading(LOADING, false));
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to delete user account!",
                    })
                );
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}

// Reset Password
export function resetUserPassword(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(LOADING, true));
        return axiosService.User.resetPassword(data).then(
            (response) => {
                dispatch(getAllUsers());
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "User password reset successfully!",
                        title: "Success",
                    })
                );
                // Close Password Modal
                setIsModalOpened(false)
                dispatch(isRequestLoading(LOADING, false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to reset user password!",
                    })
                );
                dispatch(isRequestLoading(LOADING, false))
            }
        );
    };
}
