import axiosService from "services/axios.service";
import { showErrorMessage } from "./notification";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";

//initial values
const Initial_State = {
    valueList: [],
    singleValueListData: null,
    loading: false,
    error: false,
}

// Action types
const VALUE_LISTS_DATA = "VALUE_LISTS_DATA";
const SINGLE_VALUE_LIST_DATA = "SINGLE_VALUE_LIST_DATA";
const VALUE_LIST_ITEMS = "VALUE_LIST_ITEMS";
const FETCH_LOADING = "FETCH_LOADING";
const SAVE_LOADING = "SAVE_LOADING";
const DELETE_LOADING = "DELETE_LOADING";
const ERROR = "ERROR";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case VALUE_LIST_ITEMS:
            return {
                ...state,
                error: null,
                loading: false,
                valueList: action?.payload?.data,
            };
        case VALUE_LISTS_DATA:
            return {
                ...state,
                valueListsData: action?.payload?.data,
            };
        case SINGLE_VALUE_LIST_DATA:
            return {
                ...state,
                singleValueListData: action?.payload?.data,
            };
        case FETCH_LOADING:
            return {
                ...state,
                fetchLoading: action.payload,
                error: null,
            };
        case SAVE_LOADING:
            return {
                ...state,
                saveLoading: action.payload,
            };
        case DELETE_LOADING:
            return {
                ...state,
                deleteLoading: action.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(actionType, data) {
    return {
        type: actionType,
        payload: data,
    };
};

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

export function onLoadValueListItems(data) {
    return {
        type: VALUE_LIST_ITEMS,
        payload: data,
    };
}

export function onLoadSingleValueListData(data) {
    return {
        type: SINGLE_VALUE_LIST_DATA,
        payload: data,
    };
}

export function onLoadValueListsData(data) {
    return {
        type: VALUE_LISTS_DATA,
        payload: data,
    };
}

// Actions

export function loadValueList(id) {
    return (dispatch) => {
        dispatch(isRequestLoading(FETCH_LOADING, true));
        return axiosService.ValueList.retrieveValueListItems(id).then(
            (response) => {
                dispatch(onLoadValueListItems(response));
                dispatch(isRequestLoading(FETCH_LOADING, false));
            },
            (error) => {
                // Handle Throw Error
                handleThrowError(dispatch, error)
                dispatch(isRequestLoading(FETCH_LOADING, false))
            }
        );
    };
}

export function loadSingleValueListData(id) {
    return (dispatch) => {
        dispatch(isRequestLoading(FETCH_LOADING, true));
        return axiosService.ValueList.retrieveSingleValueList(id).then(
            (response) => {
                dispatch(onLoadSingleValueListData(response));
                dispatch(isRequestLoading(FETCH_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, "Failed to load value list")
                dispatch(isRequestLoading(FETCH_LOADING, false))
            }
        );
    };
}

export function loadValueListsData() {
    return (dispatch) => {
        dispatch(isRequestLoading(FETCH_LOADING, true))
        return axiosService.ValueList.retrieveValueList().then(
            (response) => {
                dispatch(onLoadValueListsData(response));
                dispatch(isRequestLoading(FETCH_LOADING, false))
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load value lists",
                    })
                );
                dispatch(isRequestLoading(FETCH_LOADING, false))
            }
        );
    };
}

// Create Value List from Local
export function createValueListFromLocal(data, handleOnSuccess, setTableRowData, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(SAVE_LOADING, true));
        return axiosService.ValueList.createValueListFromLocal(data).then(
            (response) => {
                // Refetch all value lists
                dispatch(loadValueListsData())
                handleThrowSuccess(dispatch, "Value list created successfully!")
                dispatch(isRequestLoading(SAVE_LOADING, false));

                // If value list is created from sublist tab and we want to add it to the table immediately
                setTableRowData && setTableRowData((prevRowData) => {
                    const existingRowData = [...prevRowData]
                    const currentValuelist = response.data.data
                    existingRowData.push(
                        {
                            ...currentValuelist
                        }
                    )
                    return existingRowData
                })
                // Close store value list modal if parameter is passed
                setIsModalOpened && setIsModalOpened(false)
                handleOnSuccess && handleOnSuccess()
            },
            (error) => {
                handleThrowError(dispatch, "Failed to create value list!")
                dispatch(isRequestLoading(SAVE_LOADING, false));
            }
        );
    };
}

// Update Value List from Local
export function updateValueListFromLocal(valuelistId, data, handleOnSuccess) {
    return (dispatch) => {
        dispatch(isRequestLoading(SAVE_LOADING, true));
        return axiosService.ValueList.updateValueListFromLocal(valuelistId, data).then(
            (response) => {
                // Refetch all value lists
                dispatch(loadValueListsData())
                handleThrowSuccess(dispatch, "Value list updated successfully!")
                dispatch(isRequestLoading(SAVE_LOADING, false));
                handleOnSuccess && handleOnSuccess()
            },
            (error) => {
                handleThrowError(dispatch, "Failed to update value list!")
                dispatch(isRequestLoading(SAVE_LOADING, false));
            }
        );
    };
}

// Create Value List from Excel
export function createValueListFromExcel(formData, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(SAVE_LOADING, true));
        return axiosService.ValueList.createValueListFromExcel(formData).then(
            (response) => {
                // Refetch all value lists
                dispatch(loadValueListsData())
                handleThrowSuccess(dispatch, "Value list created successfully!")
                dispatch(isRequestLoading(SAVE_LOADING, false));
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                handleThrowError(dispatch, "Failed to create value list!")
                dispatch(isRequestLoading(SAVE_LOADING, false));
            }
        );
    };
}

// Updaye Value List from Excel
export function updateValueListFromExcel(valueListId, formData, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(SAVE_LOADING, true));
        return axiosService.ValueList.updateValueListFromExcel(valueListId, formData).then(
            (response) => {
                // Refetch all value lists
                dispatch(loadValueListsData())
                handleThrowSuccess(dispatch, "Value list updated successfully!")
                dispatch(isRequestLoading(SAVE_LOADING, false));
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                handleThrowError(dispatch, "Failed to update value list!")
                dispatch(isRequestLoading(SAVE_LOADING, false));
            }
        );
    };
}

// Delete Value List 
export function deleteValueList(valuelistId, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(DELETE_LOADING, true));
        return axiosService.ValueList.deleteValueList(valuelistId).then(
            (response) => {
                // Refetch all value lists
                dispatch(loadValueListsData())
                handleThrowSuccess(dispatch, "Value list deleted successfully!")
                dispatch(isRequestLoading(DELETE_LOADING, false));
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                handleThrowError(dispatch, "Failed to delete value list!")
                dispatch(isRequestLoading(DELETE_LOADING, false));
            }
        );
    };
}