import { combineReducers } from "redux";
import auth from "./auth";
import notification from "./notification";
import setup from "./setup";
import category from "./category";
import dashboard from "./dashboard";
import headerSearch from "./headerSearch";
import appNotification from "./appNotification";
import file from "./file";
import filter from "./filter";
import filecategory from "./filecategory";
import group from "./group";
import valueList from "./valueList";
import user from "./user";
import fileAction from "./fileAction";
import workflow from "./workflow";
import adminSetup from "./admin-modules/adminSetup"
import metadata from "./metadata"
import auditTrail from "./auditTrail"
import emailNotification from "./emailNotification"
import backup from "./backup"
import settings from "./settings"
import trash from "./trash"
import fileTag from "./fileTag"
import licenseManagement from "./licenseManagement"

export default combineReducers({
  auth,
  notification,
  setup,
  category,
  dashboard,
  headerSearch,
  appNotification,
  file,
  filter,
  filecategory,
  group,
  valueList,
  user,
  fileAction,
  workflow,
  metadata,
  // Admin
  adminSetup,
  auditTrail,
  emailNotification,
  backup,
  settings,
  trash,
  licenseManagement,
  fileTag
});

