import { useEffect, useState } from "react";
import { MESSAGE_TYPE } from "store/constant";
import { messageCleared, showErrorMessage, showSuccessMessage } from "store/modules/notification";
import styled from "styled-components";

const Div = styled.div`
  width: 20%;
  position: absolute;
  right: 0;
  left:0;
  top: 4rem;
  z-index: 393939;
  font-size: 0.85em;
  border-radius: 0;
  font-weight: 700;
  padding: 0.5rem;
  margin: 0 auto;
`;
export function Success({ message }) {
  const [showMessage, setShowMessage] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {message && (
        <>
          {showMessage && (
            <Div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <button
                type="button"
                className="btn-close pt-1"
                aria-label="Close"
                onClick={() => setShowMessage(false)}
              />
              <div>{message}</div>
            </Div>
          )}
        </>
      )}
    </>
  );
}

export function Warning({ message }) {
  const [showMessage, setShowMessage] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {message && (
        <>
          {showMessage && (
            <Div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <button
                type="button"
                className="btn-close pt-1"
                aria-label="Close"
                onClick={() => setShowMessage(false)}
              />
              <div>{message}</div>
            </Div>
          )}
        </>
      )}
    </>
  );
}

export function Info({ message }) {
  const [showMessage, setShowMessage] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {message && (
        <>
          {showMessage && (
            <Div
              className="alert alert-info alert-dismissible fade show"
              role="alert"
            >
              <button
                type="button"
                className="btn-close pt-1"
                aria-label="Close"
                onClick={() => setShowMessage(false)}
              />
              <div>{message}</div>
            </Div>
          )}
        </>
      )}
    </>
  );
}

export function Error({ message }) {
  const [showMessage, setShowMessage] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {message && (
        <>
          {showMessage && (
            <Div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <button
                type="button"
                className="btn-close pt-1"
                aria-label="Close"
                onClick={() => setShowMessage(false)}
              />
              <div style={{ marginRight: "20px" }} dangerouslySetInnerHTML={{ __html: message }}></div>
            </Div>
          )}
        </>
      )}
    </>
  );
}

export const handleThrowError = function (dispatch, errorMessage) {
  dispatch(messageCleared())
  dispatch(
    showErrorMessage({
      type: "error",
      message: errorMessage,
      title: errorMessage,
    })
  );
}

export const handleThrowSuccess = function (dispatch, successMessage) {
  dispatch(messageCleared())
  dispatch(
    showSuccessMessage({
      type: MESSAGE_TYPE.SUCCESS,
      message: successMessage,
      title: "Success",
    })
  );
}

