import axiosService from "services/axios.service";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";

//initial values
const Initial_State = {
    tags: [],
    saveLoading: false,
    deleteLoading: false,
    fetchLoading: false,
};

// Action types
const LOAD_RELATED_FILE_TAGS = "LOAD_RELATED_FILE_TAGS";
const SAVE_ACTION_LOADING = "SAVE_ACTION_LOADING";
const DELETE_ACTION_LOADING = "DELETE_ACTION_LOADING";
const FETCH_LOADING = "FETCH_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case LOAD_RELATED_FILE_TAGS:
            return {
                ...state,
                loading: false,
                tags: action?.payload?.data?.data,
            };
        case SAVE_ACTION_LOADING:
            return {
                ...state,
                saveLoading: action?.payload,
            };
        case DELETE_ACTION_LOADING:
            return {
                ...state,
                deleteLoading: action?.payload,
            };
        case FETCH_LOADING:
            return {
                ...state,
                fetchLoading: action?.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(constant, data) {
    return {
        type: constant,
        payload: data,
    };
}

export function onLoadData(constant, data) {
    return {
        type: constant,
        payload: data,
    };
}


// Actions
// Load all Related file tags for a category
export function loadRelatedFileTagsForCategory(categoryId) {
    return (dispatch) => {
        dispatch(isRequestLoading(FETCH_LOADING, true));
        return axiosService.FileCategory.loadRelatedFileTagsForCategory(categoryId).then(
            (response) => {
                dispatch(onLoadData(LOAD_RELATED_FILE_TAGS, response));
                dispatch(isRequestLoading(FETCH_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, "Failed to load related file tags!")
                dispatch(isRequestLoading(FETCH_LOADING, false));
            }
        );
    };
}


// Create related file tags for a category
export function createRelatedFileTagsForCategory(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(SAVE_ACTION_LOADING, true));
        return axiosService.FileCategory.createRelatedFileTagsForCategory(data).then(
            (response) => {
                // Close Store Modal
                setIsModalOpened && setIsModalOpened(false)
                handleThrowSuccess(dispatch, "Related files tag created successfully!")
                dispatch(isRequestLoading(SAVE_ACTION_LOADING, false));
                dispatch(loadRelatedFileTagsForCategory(data.folder_id));
            },
            (error) => {
                // Close Store Modal
                setIsModalOpened && setIsModalOpened(false)
                handleThrowError(dispatch, "Failed to create related file tag")
                dispatch(isRequestLoading(SAVE_ACTION_LOADING, false));
            }
        );
    };
}

// Update related file tags for a category
export function updateRelatedFileTagsForCategory(data, folderId, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(SAVE_ACTION_LOADING, true));
        return axiosService.FileCategory.updateRelatedFileTagsForCategory(data).then(
            (response) => {
                // Close Store Modal
                setIsModalOpened && setIsModalOpened(false)
                handleThrowSuccess(dispatch, "Related files tag updated successfully!")
                dispatch(isRequestLoading(SAVE_ACTION_LOADING, false));
                dispatch(loadRelatedFileTagsForCategory(folderId));
            },
            (error) => {
                // Close Store Modal
                setIsModalOpened && setIsModalOpened(false)
                handleThrowError(dispatch, "Failed to update related file tag")
                dispatch(isRequestLoading(SAVE_ACTION_LOADING, false));
            }
        );
    };
}

// Delete related file tags for a category
export function deleteRelatedFileTagsForCategory(tagId, folderId, handleOnDeleteTagSuccess) {
    return (dispatch) => {
        dispatch(isRequestLoading(DELETE_ACTION_LOADING, true));
        return axiosService.FileCategory.deleteRelatedFileTagsForCategory(tagId).then(
            (response) => {
                // Close confirmation Modal
                handleOnDeleteTagSuccess && handleOnDeleteTagSuccess()
                handleThrowSuccess(dispatch, "Related files tag deleted successfully!")
                dispatch(isRequestLoading(DELETE_ACTION_LOADING, false));
                dispatch(loadRelatedFileTagsForCategory(folderId));
            },
            (error) => {
                // Close confirmation Modal
                handleOnDeleteTagSuccess && handleOnDeleteTagSuccess(true)
                handleThrowError(dispatch, "Failed to delete related file tag")
                dispatch(isRequestLoading(DELETE_ACTION_LOADING, false));
            }
        );
    };
}

