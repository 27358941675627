import axiosService from "services/axios.service";
import { showErrorMessage, showSuccessMessage, } from "./notification";
import { MESSAGE_TYPE } from "store/constant";


//initial values
const Initial_State = {
    allMetadataFields: [],
    usersMetadataFields: [],
    loading: false,
    error: false,

};

// Action types
const METADATA_FIELDS = "METADATA_FIELDS";
const USERS_METADATA_FIELDS = "USERS_METADATA_FIELDS";
const METADATA_LOADING = "LOADING";
const SAVE_METADATA_LOADING = "SAVE_METADATA_LOADING";
const DELETE_METADATA_LOADING = "DELETE_METADATA_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case METADATA_FIELDS:
            return {
                ...state,
                error: null,
                loading: false,
                allMetadataFields: action?.payload?.data,
            };
        case USERS_METADATA_FIELDS:
            return {
                ...state,
                error: null,
                loading: false,
                usersMetadataFields: action?.payload?.data,
            };
        case METADATA_LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case SAVE_METADATA_LOADING:
            return {
                ...state,
                saveMetadataLoading: action.payload,
            };
        case DELETE_METADATA_LOADING:
            return {
                ...state,
                deleteMetadataLoading: action.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: METADATA_LOADING,
        payload: data,
    };
}

export function isSaveMetadataLoading(data) {
    return {
        type: SAVE_METADATA_LOADING,
        payload: data,
    };
}

export function isDeleteMetadataLoading(data) {
    return {
        type: DELETE_METADATA_LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Filter Response
export function onMetadataFieldsLoad(data, constant) {
    return {
        type: constant,
        payload: data,
    };
}


// Actions
export function getAllMetadataFields() {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Metadata.retrieveAllMetadataFields().then(
            (response) => {
                dispatch(onMetadataFieldsLoad(response, METADATA_FIELDS));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load all metadata!",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}

// Get all users metadata fields
export function getAllUsersMetadataFields() {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Metadata.retrieveAllUsersMetadataFields().then(
            (response) => {
                dispatch(onMetadataFieldsLoad(response, USERS_METADATA_FIELDS));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load users metadata!",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}


// Create Metadata Field
export function createMetadataField(data, setTableRowData) {
    return (dispatch) => {
        dispatch(isSaveMetadataLoading(true));
        return axiosService.Metadata.storeMetadataField(data).then(
            (response) => {
                dispatch(isSaveMetadataLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "Metadata field created!",
                        title: "Success",
                    })
                );
                dispatch(getAllMetadataFields());
                // If metadata is created from categories and we want to add it to the table immediately
                setTableRowData && setTableRowData((prevRowData) => {
                    const existingRowData = [...prevRowData]
                    existingRowData.push(
                        {
                            ...response.data.data,
                            key: Math.floor(Math.random() * 10000),
                        }
                    )
                    return existingRowData
                })
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to created metadata field!",
                    })
                );
                dispatch(isSaveMetadataLoading(false));
            }
        );
    };
}

// Update Metadata Field
export function updateMetadataField(data) {
    return (dispatch) => {
        dispatch(isSaveMetadataLoading(true));
        return axiosService.Metadata.storeMetadataField(data).then(
            (response) => {
                dispatch(isSaveMetadataLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "Metadata field updated!",
                        title: "Success",
                    })
                );
                dispatch(getAllMetadataFields());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to updated metadata field!",
                    })
                );
                dispatch(isSaveMetadataLoading(false));
            }
        );
    };
}

// Delete Metadata Field
export function deleteMetadataField(data, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isDeleteMetadataLoading(true));
        return axiosService.Metadata.deleteMetadataField(data).then(
            (response) => {
                dispatch(isDeleteMetadataLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "Metadata field deleted!",
                        title: "Success",
                    })
                );
                setIsToClearInputStates(true)
                dispatch(getAllMetadataFields());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to delete metadata field!",
                    })
                );
                dispatch(isDeleteMetadataLoading(false));
            }
        );
    };
}