import axiosService from "services/axios.service";
import { showErrorMessage, showSuccessMessage, } from "./notification";
import { MESSAGE_TYPE } from "store/constant";


//initial values
const Initial_State = {
    trashFiles: [],
    fileDetail: [],
    loading: false,
    error: false,

};

// Action types
const GET_TRASH_FILES = "GET_TRASH_FILES";
const GET_TRASHED_FILE_DETAIL = "GET_TRASHED_FILE_DETAIL";
const TRASH_LOADING = "TRASH_LOADING";
const RESTORE_TRASH_LOADING = "RESTORE_TRASH_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case GET_TRASH_FILES:
            return {
                ...state,
                error: null,
                loading: false,
                trashFiles: action?.payload?.data,
            };
        case GET_TRASHED_FILE_DETAIL:
            return {
                ...state,
                error: null,
                loading: false,
                fileDetail: action?.payload?.data,
            };
        case TRASH_LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case RESTORE_TRASH_LOADING:
            return {
                ...state,
                restoreLoading: action.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: TRASH_LOADING,
        payload: data,
    };
}

export function isRestoreTrashLoading(data) {
    return {
        type: RESTORE_TRASH_LOADING,
        payload: data,
    };
};

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Filter Response
export function onGetTrashedFilesLoad(data) {
    return {
        type: GET_TRASH_FILES,
        payload: data,
    };
}


export function onTrashFileDetailLoad(data) {
    return {
        type: GET_TRASHED_FILE_DETAIL,
        payload: data,
    };
}


// Actions
export function getAllTrashFiles(quantity = 20, page = 1,) {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Trash.getAllTrashFiles(quantity, page).then(
            (response) => {
                dispatch(onGetTrashedFilesLoad(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load all trash!",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}


// Load Trash File Detail
export function loadTrashFileDetail(fileId) {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Trash.retrieveTrashedFileDetail(fileId).then(
            (response) => {
                dispatch(onTrashFileDetailLoad(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load file data",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}

// Restore Trashed File
export function restoreTrashedFile(fileId, handleToggleFileTrashModal) {
    return (dispatch) => {
        dispatch(isRestoreTrashLoading(true));
        return axiosService.Trash.restoreTrashedFile(fileId).then(
            (response) => {
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "Trash file restored!",
                        title: "Success",
                    })
                );
                dispatch(isRestoreTrashLoading(false));
                dispatch(getAllTrashFiles(20, 1));

                // Close Modal
                handleToggleFileTrashModal && handleToggleFileTrashModal()
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to restore trash file!",
                    })
                );
                dispatch(isRestoreTrashLoading(false));
            }
        );
    };
}

// Delete Trashed File
export function deleteTrashedFile(fileId, handleToggleFileTrashModal) {
    return (dispatch) => {
        dispatch(isRestoreTrashLoading(true));
        return axiosService.Trash.deleteTrashedFile(fileId).then(
            (response) => {
                dispatch(
                    showSuccessMessage({
                        type: MESSAGE_TYPE.SUCCESS,
                        message: "Trash file deleted!",
                        title: "Success",
                    })
                );
                dispatch(isRestoreTrashLoading(false));
                dispatch(getAllTrashFiles(20, 1));
                // Close Modal
                handleToggleFileTrashModal && handleToggleFileTrashModal()
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to delete trash file!",
                    })
                );
                dispatch(isRestoreTrashLoading(false));
            }
        );
    };
}

