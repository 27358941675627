import axiosService from "services/axios.service";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";

//initial values
const Initial_State = {
    workflows: [],
    allWorkflows: [],
    workflowDetails: [],
    workflowStep: [],
    notifRecipient: [],
    stepDetails: null,
    stepDetailsLoading: false,
    loading: false,
    error: false,

};

// Action types
const WORKFLOWS = "WORKFLOWS";
const ALL_WORKFLOWS = "ALL_WORKFLOWS";
const WORKFLOW_DETAILS = "WORKFLOW_DETAILS";
const WORKFLOW_STEP = "WORKFLOW_STEP";
const WORKFLOW_LOADING = "WORKFLOW_LOADING";
const IS_CREATING_LOADING = "IS_CREATING_LOADING";
const IS_DELETE_LOADING = "IS_DELETE_LOADING";
const ERROR = "ERROR";
// Step Details
const NOTIFICATION_RECIPIENT = "NOTIFICATION_RECIPIENT"
const STEP_DETAILS_LOADING = "STEP_DETAILS_LOADING"
const STEP_DETAILS = "STEP_DETAILS"



// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case WORKFLOW_STEP:
            return {
                ...state,
                error: null,
                loading: false,
                workflowStep: action?.payload?.data?.data,
            };
        case WORKFLOWS:
            return {
                ...state,
                workflows: action?.payload?.data,
            };
        case ALL_WORKFLOWS:
            return {
                ...state,
                allWorkflows: action?.payload,
            };
        case WORKFLOW_DETAILS:
            return {
                ...state,
                workflowDetails: action?.payload,
            };
        case WORKFLOW_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case IS_CREATING_LOADING:
            return {
                ...state,
                saveLoading: action.payload,
            };
        case IS_DELETE_LOADING:
            return {
                ...state,
                deleteLoading: action.payload,
            };
        // Step Details 
        case STEP_DETAILS:
            return {
                ...state,
                stepDetails: action?.payload,
            };
        case NOTIFICATION_RECIPIENT:
            return {
                ...state,
                notifRecipient: action?.payload,
            };
        case STEP_DETAILS_LOADING:
            return {
                ...state,
                stepDetailsLoading: action.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(constant, data) {
    return {
        type: constant,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}


// Workflow Step
export function onGetWorkflowStepLoad(data) {
    return {
        type: WORKFLOW_STEP,
        payload: data,
    };
}

// Workflows Only 
export function onGetWorkflowsLoad(constant, data) {
    return {
        type: constant,
        payload: data,
    };
}

export function onGetWorkflowDetailsLoad(data) {
    return {
        type: WORKFLOW_DETAILS,
        payload: data,
    };
}


export function onGetStepDetailsLoad(constant, data) {
    return {
        type: constant,
        payload: data,
    };
}

// Actions
export function getWorkflowStep(id) {
    return (dispatch) => {
        dispatch(isRequestLoading(WORKFLOW_LOADING, true));
        return axiosService.Workflow.getWorkflowStep(id).then(
            (response) => {
                dispatch(onGetWorkflowStepLoad(response));
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load workflow step")
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            }
        );
    };
}

export function getWorkflowsOnly() {
    return (dispatch) => {
        dispatch(isRequestLoading(WORKFLOW_LOADING, true));
        return axiosService.Workflow.getWorkflowOnly().then(
            (response) => {
                dispatch(onGetWorkflowsLoad(WORKFLOWS, response));
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load workflows")
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            }
        );
    };
}

export function getAllWorkflows() {
    return (dispatch) => {
        dispatch(isRequestLoading(WORKFLOW_LOADING, true));
        return axiosService.Workflow.getAllWorkflows().then(
            (response) => {
                dispatch(onGetWorkflowsLoad(ALL_WORKFLOWS, response.data));
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load workflows")
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            }
        );
    };
}

// Initiate Workflow
export function initiateWorkflow(data, navigate) {
    return (dispatch) => {
        dispatch(isRequestLoading(WORKFLOW_LOADING, true));
        return axiosService.Workflow.initiateWorkflow(data).then(
            (response) => {
                navigate("/unindexed-files", { state: { categoryId: data.folder_id } })
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to initiate workflow!")
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            }
        );
    };
}

// Save(Create and Update) Workflow
export function saveWorkflow(data, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.saveWorkflow(data).then(
            (response) => {
                // Refetch all workflows
                dispatch(getAllWorkflows())
                // Message Handler
                const message = `Workflow ${data.id ? "updated" : "created"} successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Clear inputs on success
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                const message = `Failed to ${data.id ? "update" : "create"} workflow!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}

// Get Workflow by Id 
export function getWorkflowById(workflowId) {
    return (dispatch) => {
        dispatch(isRequestLoading(WORKFLOW_LOADING, true));
        return axiosService.Workflow.getWorkflowById(workflowId).then(
            (response) => {
                dispatch(onGetWorkflowDetailsLoad(response.data.data));
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load workflow details!")
                dispatch(isRequestLoading(WORKFLOW_LOADING, false));
            }
        );
    };
}
// Delete Workflow
export function deleteWorkflow(workflowId, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_DELETE_LOADING, true));
        return axiosService.Workflow.deleteWorkflow(workflowId).then(
            (response) => {
                // Refetch all workflows
                dispatch(getAllWorkflows())
                handleThrowSuccess(dispatch, "Workflow deleted successfully!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false));
                // Clear inputs on success
                setIsToClearInputStates && setIsToClearInputStates(true)
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to delete workflow!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false))
            }
        );
    };
}


// STEPS || STATE
// Save(Create and Update) Workflow Step
export function saveWorkflowStep(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.saveWorkflowStep(data).then(
            (response) => {
                // Refetch current workflow details
                dispatch(getWorkflowById(data.workflow_id))
                // Message Handler
                const message = `Workflow step ${data.id ? "updated" : "created"} successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = `Failed to ${data.id ? "update" : "create"} workflow step!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}


// Delete Workflow Step
export function deleteWorkflowStep(workflowStepId, workflowId, setIsConfirmDeleteStateModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_DELETE_LOADING, true));
        return axiosService.Workflow.deleteWorkflowStep(workflowStepId).then(
            (response) => {
                // Refetch current workflow details
                dispatch(getWorkflowById(workflowId))
                handleThrowSuccess(dispatch, "Workflow deleted successfully!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false));
                // Close confirmation modal
                setIsConfirmDeleteStateModalOpened && setIsConfirmDeleteStateModalOpened(false)
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to delete workflow!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false))
            }
        );
    };
}


// WORKFLOW METADATA
// Save(Create and Update) Workflow Metadata
export function saveWorkflowMetadata(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.saveWorkflowMetadata(data).then(
            (response) => {
                // Refetch current workflow details
                dispatch(getWorkflowById(data.id))
                // Message Handler
                const message = `Workflow metadata updated successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = `Failed to update workflow metadata!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}



// STEP NOTIFICATIONS
// Get  Notification Recipient
export function getNotificationRecipient(stepId) {
    return (dispatch) => {
        // dispatch(isRequestLoading(STEP_DETAILS_LOADING, true));
        return axiosService.Workflow.getNotificationRecipient().then(
            (response) => {
                dispatch(onGetStepDetailsLoad(NOTIFICATION_RECIPIENT, response.data.data));
                // dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load step notifications!")
                // dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            }
        );
    };
}

// Get Step Notifications
export function getStepNotifications(stepId) {
    return (dispatch) => {
        dispatch(isRequestLoading(STEP_DETAILS_LOADING, true));
        return axiosService.Workflow.getNotifications(stepId).then(
            (response) => {
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, response.data.data));
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load step notifications!")
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, []));
            }
        );
    };
}

// Save(Create and Update) Step Notification
export function saveStepNotification(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.updateNotification(data).then(
            (response) => {
                // Refetch step notification details
                dispatch(getStepNotifications(data.workflow_step_id))
                // Message Handler
                const message = `Step notification ${data?.id ? "updated" : "created"} successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = `Failed to  ${data?.id ? "update" : "create"} step notification!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}

// Delete  Step Notification
export function deleteStepNotification(notificationId, stepId, setIsConfirmDeleteStateModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_DELETE_LOADING, true));
        return axiosService.Workflow.deleteNotification(notificationId).then(
            (response) => {
                // Refetch step notification details
                dispatch(getStepNotifications(stepId))
                handleThrowSuccess(dispatch, "Notification deleted successfully!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false));
                // Close confirmation modal
                setIsConfirmDeleteStateModalOpened && setIsConfirmDeleteStateModalOpened(false)
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to delete notification!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false))
            }
        );
    };
}


// STEP ASSIGNEES || OWNERS
// Get Step Assignees
export function getStepAssignees(stepId) {
    return (dispatch) => {
        dispatch(isRequestLoading(STEP_DETAILS_LOADING, true));
        return axiosService.Workflow.getAssignees(stepId).then(
            (response) => {
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, response.data.data));
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load step assignees!")
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, []));
            }
        );
    };
}

// Save(Create and Update) Step Assignee
export function saveStepAssignee(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.updateAssignee(data).then(
            (response) => {
                // Refetch step notification assignees
                dispatch(getStepAssignees(data.workflow_step_id))
                // Message Handler
                const message = `Step assignee ${data?.id ? "updated" : "created"} successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = error || `Failed to  ${data?.id ? "update" : "create"} step assignee!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}

// Delete  Step Assignee
export function deleteStepAssignee(assigneeId, stepId, setIsConfirmDeleteStateModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_DELETE_LOADING, true));
        return axiosService.Workflow.deleteAssignee(assigneeId).then(
            (response) => {
                // Refetch step assignees details
                dispatch(getStepAssignees(stepId))
                handleThrowSuccess(dispatch, "Assignee deleted successfully!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false));
                // Close confirmation modal
                setIsConfirmDeleteStateModalOpened && setIsConfirmDeleteStateModalOpened(false)
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to delete assignee!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false))
            }
        );
    };
}

// STEP CONDITIONS
// Get Step PRE Conditions
export function getStepPreConditions(data) {
    return (dispatch) => {
        dispatch(isRequestLoading(STEP_DETAILS_LOADING, true));
        return axiosService.Workflow.getPreCondition(data).then(
            (response) => {
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, response.data.data));
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load step pre-conditions!")
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, []));
            }
        );
    };
}

// Get Step POST Conditions
export function getStepPostConditions(data) {
    return (dispatch) => {
        dispatch(isRequestLoading(STEP_DETAILS_LOADING, true));
        return axiosService.Workflow.getPostCondition(data).then(
            (response) => {
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, response.data.data));
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load step post-conditions!")
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, []));
            }
        );
    };
}


// Save(Create and Update) Step Conditions
export function saveStepCondition(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.updateCondition(data).then(
            (response) => {
                // Refetch step conditions 
                data.mode === "PRE" ?
                    dispatch(getStepPreConditions({
                        mode: "PRE",
                        step_id: data.workflow_step_id,
                    }))
                    :
                    dispatch(getStepPostConditions({
                        mode: "POST",
                        step_id: data.workflow_step_id,
                    }))
                // Message Handler
                const message = `Step ${data.mode}-condition ${data?.id ? "updated" : "created"} successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = `Failed to  ${data?.id ? "update" : "create"} step ${data.mode}-condition!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}

// Delete  Step Conditions
export function deleteStepConditions(mode, conditionId, stepId, setIsConfirmDeleteStateModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_DELETE_LOADING, true));
        return axiosService.Workflow.deleteCondition(conditionId).then(
            (response) => {
                // Refetch step condition details
                mode === "PRE" ?
                    dispatch(getStepPreConditions({
                        mode: "PRE",
                        step_id: stepId,
                    }))
                    :
                    dispatch(getStepPostConditions({
                        mode: "POST",
                        step_id: stepId,
                    }))
                handleThrowSuccess(dispatch, `${mode}-Condition deleted successfully!`)
                dispatch(isRequestLoading(IS_DELETE_LOADING, false));
                // Close confirmation modal
                setIsConfirmDeleteStateModalOpened && setIsConfirmDeleteStateModalOpened(false)
            },
            (error) => {
                handleThrowError(dispatch, error || `Failed to delete ${mode}-Condition!`)
                dispatch(isRequestLoading(IS_DELETE_LOADING, false))
            }
        );
    };
}


// STEP TRIGGERS
// Get Step Triggers
export function getStepTriggers(stepId) {
    return (dispatch) => {
        dispatch(isRequestLoading(STEP_DETAILS_LOADING, true));
        return axiosService.Workflow.getTriggers(stepId).then(
            (response) => {
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, response.data.data));
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load step triggers!")
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, []));
            }
        );
    };
}


// Save(Create and Update) Step Trigger
export function saveStepTrigger(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.updateTrigger(data).then(
            (response) => {
                // Refetch current step triggers
                dispatch(getStepTriggers(data.workflow_step_id))
                // Message Handler
                const message = `Step trigger updated successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = `Failed to update step trigger!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}


// Delete  Step Trigger
export function deleteStepTrigger(triggerId, stepId, setIsConfirmDeleteStateModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_DELETE_LOADING, true));
        return axiosService.Workflow.deleteTrigger(triggerId).then(
            (response) => {
                // Refetch step triggers details
                dispatch(getStepTriggers(stepId))
                handleThrowSuccess(dispatch, "Trigger deleted successfully!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false));
                // Close confirmation modal
                setIsConfirmDeleteStateModalOpened && setIsConfirmDeleteStateModalOpened(false)
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to delete trigger!")
                dispatch(isRequestLoading(IS_DELETE_LOADING, false))
            }
        );
    };
}


// STEP METADATA
// Get Step Metadata
export function getStepMetadata(stepId) {
    return (dispatch) => {
        dispatch(isRequestLoading(STEP_DETAILS_LOADING, true));
        return axiosService.Workflow.getStepMetadata(stepId).then(
            (response) => {
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, response.data.data.metadata));
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
            },
            (error) => {
                handleThrowError(dispatch, error || "Failed to load step metadata!")
                dispatch(isRequestLoading(STEP_DETAILS_LOADING, false));
                dispatch(onGetStepDetailsLoad(STEP_DETAILS, []));
            }
        );
    };
}


// Save(Create and Update) Step Metadata
export function saveStepMetadata(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.updateStepMetadata(data).then(
            (response) => {
                // Refetch current step metadata
                dispatch(getStepMetadata(data.workflow_step_id))
                // Message Handler
                const message = `Step metadata updated successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = `Failed to update step metadata!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    };
}


// Set Default Value for Workflow Step Metadata
export function saveDefaultValueForWorflowStepMetadata(data, setIsModalOpened) {
    return (dispatch) => {
        dispatch(isRequestLoading(IS_CREATING_LOADING, true));
        return axiosService.Workflow.setDefaultValueForWorflowStepMetadata(data).then(
            (response) => {
                // Refetch current step metadata
                dispatch(getStepMetadata(data.workflow_step_id))
                // Message Handler
                const message = `Step metadata default value set successfully!`
                handleThrowSuccess(dispatch, message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false));
                // Close modal
                setIsModalOpened && setIsModalOpened(false)
            },
            (error) => {
                const message = `Failed to set step metadata default value!`
                handleThrowError(dispatch, error || message)
                dispatch(isRequestLoading(IS_CREATING_LOADING, false))
            }
        );
    }
}


