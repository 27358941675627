import axiosService from "services/axios.service";
import { MESSAGE_TYPE } from "store/constant";
import { messageCleared, showErrorMessage, showSuccessMessage } from "./notification";
import { loadFileCategoyDetail, loadFilesInCategory } from "./filecategory";
import { loadAppNotification } from "./appNotification";
import { loadDashboardData } from "./dashboard";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";
import { binaryToPDFConverter } from "utils/binaryToPDFConverter";

//initial values
const Initial_State = {
  fileDetail: null,
  fileDetailForUnindexedFile: null,
  fileLoading: false,
  specialFilesLoading: false,
  unindexedFilesLoading: true,
  error: false,
  dataToSend: {},
  unindexedFiles: null,
  assignedToMeFiles: {},
  checkedOutFiles: {},
  favouriteFiles: {},
  fileSettingsLoading: false,
  filePermissions: [],
  fileVersionHistory: [],
  workflowAndStage: {},
  incrementalValue: null
};

// Action types
const FILE_DETAIL = "FILE_DETAIL";
const FILE_DETAIL_FOR_UNINDEXED_FILE = "FILE_DETAIL_FOR_UNINDEXED_FILE";
const FILE_LOADING = "FILE_LOADING";
const ERROR = "ERROR";
const DATA_TO_UPLOAD = "DATA_TO_UPLOAD";
const UNINDEXED_FILES = "UNINDEXED_FILES";
const UNINDEXED_FILES_LOADING = "UNINDEXED_FILES_LOADING";
// Assigned To Me Files
const ASSIGNED_FILES = "ASSIGNED_FILES";
// Checked Out Files
const CHECKED_OUT_FILES = "CHECKED_OUT_FILES";
// Favourite
const FAVOURITE_FILES = "FAVOURITE_FILES";
const SPECIAL_FILES_LOADING = "SPECIAL_FILES_LOADING";
// File Settings
const FILE_SETTINGS_LOADING = "FILE_SETTINGS_LOADING";
const FILE_PERMISSIONS = "FILE_PERMISSIONS"
const FILE_VERSION_HISTORY = "FILE_VERSION_HISTORY"
// Workflow and Stage
const WORKFLOW_AND_STAGE = "WORKFLOW_AND_STAGE"
// Incremental Value
const INCREMENTAL_VALUE_DATA = "INCREMENTAL_VALUE_DATA"


// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case FILE_DETAIL:
      return {
        ...state,
        error: null,
        fileSettingsLoading: false,
        fileDetail: action?.payload?.data,
      };
    case FILE_DETAIL_FOR_UNINDEXED_FILE:
      return {
        ...state,
        fileDetailForUnindexedFile: action?.payload?.data,
      };
    case UNINDEXED_FILES:
      return {
        ...state,
        error: null,
        unindexedFiles: action?.payload?.data,
      };
    case ASSIGNED_FILES:
      return {
        ...state,
        error: null,
        assignedLoading: false,
        assignedToMeFiles: action?.payload?.data,
      };
    case CHECKED_OUT_FILES:
      return {
        ...state,
        error: null,
        checkedOutLoading: false,
        checkedOutFiles: action?.payload?.data,
      };
    case FAVOURITE_FILES:
      return {
        ...state,
        error: null,
        favouriteFiles: action?.payload?.data,
      };
    case FILE_LOADING:
      return {
        ...state,
        fileLoading: action.payload,
        error: null,
      };
    case SPECIAL_FILES_LOADING:
      return {
        ...state,
        specialFilesLoading: action.payload,
        error: null,
      };
    case UNINDEXED_FILES_LOADING:
      return {
        ...state,
        unindexedFilesLoading: action.payload,
        error: null,
      };
    case DATA_TO_UPLOAD:
      return {
        ...state,
        dataToSend: action.payload,
      };
    case FILE_SETTINGS_LOADING:
      return {
        ...state,
        fileSettingsLoading: action.payload,
        error: null,
      };
    case FILE_PERMISSIONS:
      return {
        ...state,
        filePermissions: action.payload?.data,
        error: null,
      };
    case FILE_VERSION_HISTORY:
      return {
        ...state,
        fileVersionHistory: action.payload?.data?.data,
        error: null,
      };
    case WORKFLOW_AND_STAGE:
      return {
        ...state,
        workflowAndStage: action.payload?.data?.data,
        error: null,
      };
    case INCREMENTAL_VALUE_DATA:
      return {
        ...state,
        incrementalValue: action.payload?.data,
        error: null,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function isRequestLoading(data) {
  return {
    type: FILE_LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}

export function storeDataToUpload(data) {
  return {
    type: DATA_TO_UPLOAD,
    payload: data,
  };
}

export function onFileDetailLoad(data) {
  return {
    type: FILE_DETAIL,
    payload: data,
  };
}

export function onFileDetailLoadForUnindexedFile(data) {
  return {
    type: FILE_DETAIL_FOR_UNINDEXED_FILE,
    payload: data,
  };
}

export function onUnindexedFileLoad(data) {
  return {
    type: UNINDEXED_FILES,
    payload: data,
  };
}

// Assigned To Me Files
export function onAssignedToMeFileLoad(data) {
  return {
    type: ASSIGNED_FILES,
    payload: data,
  };
}

// Check Out Files
export function onCheckedOutFileLoad(data) {
  return {
    type: CHECKED_OUT_FILES,
    payload: data,
  };
}

// Favourites
export function onFavouritesFileLoad(data) {
  return {
    type: FAVOURITE_FILES,
    payload: data,
  };
}

export function isSpecialFilesLoading(data) {
  return {
    type: SPECIAL_FILES_LOADING,
    payload: data,
  };
}

export function isUnindexedLoading(data) {
  return {
    type: UNINDEXED_FILES_LOADING,
    payload: data,
  };
}

// FILE Settings
export function isFileSettingsLoading(data) {
  return {
    type: FILE_SETTINGS_LOADING,
    payload: data,
  };
}

export function onFilePermissionsLoad(data) {
  return {
    type: FILE_PERMISSIONS,
    payload: data,
  };
}

export function onFileVersionHistoryLoad(data) {
  return {
    type: FILE_VERSION_HISTORY,
    payload: data,
  };
}

// Workflow and Stage
export function onWorkflowAndStageLoad(data) {
  return {
    type: WORKFLOW_AND_STAGE,
    payload: data,
  };
}

// Workflow and Stage
export function onIncrementalValueLoad(data) {
  return {
    type: INCREMENTAL_VALUE_DATA,
    payload: data,
  };
}


// Actions
export function loadFile(id) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.loadFileDetail(id).then(
      (response) => {
        dispatch(onFileDetailLoad(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file data",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Load File Detail For Unindexed File
export function loadFileDetailForUnindexedFile(id) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.loadFileDetail(id).then(
      (response) => {
        dispatch(onFileDetailLoadForUnindexedFile(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        // Throw Error Message
        handleThrowError(dispatch, error || "Failed to load file data");
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Load File Publicly e.g For Shared Files
export function loadFilePublicly(id) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.loadFileDetailPublicly(id).then(
      (response) => {
        dispatch(onFileDetailLoad(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file data",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function uploadFile(data, formData, config = null, navigate) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    dispatch(storeDataToUpload(data));

    return axiosService.File.uploadFile(formData, config).then(
      (response) => {
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File uploaded successfully",
            title: "Success",
          })
        );
        navigate("/unindexed-files", { state: { categoryId: data.folder_id } });
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to upload file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function uploadOtherFile(data, id, handleOnSuccess, isFromUnindexedFiles = false) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.uploadOtherFile(data).then(
      (response) => {
        dispatch(isRequestLoading(false));
        // Handle Throw Success and Reload File
        handleThrowSuccess(dispatch, "File uploaded successfully");

        // Load file detail if related files ARE NOT being uploaded from unindexed files
        !isFromUnindexedFiles &&
          dispatch(loadFile(id));

        // Load file detail for unindexed(uncategorized) file if related files ARE being uploaded from unindexed files
        isFromUnindexedFiles && dispatch(loadFileDetailForUnindexedFile(id));
        // On Success callback
        handleOnSuccess && handleOnSuccess();
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to upload file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}


// Download Selected Files
export function downloadSelectedFiles(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.downloadSelectedFiles(data).then(
      (response) => {
        // Download file to local
        const binaryData = response.data;
        binaryToPDFConverter(binaryData, "selected_files");

        // Throw Success message
        handleThrowSuccess(dispatch, "File downloaded successfully");
        dispatch(isRequestLoading(false));
      },
      (error) => {
        // Throw error message
        handleThrowError(dispatch, error || "Failed to download file");
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function editMetadata(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.editFileMetadata(data).then(
      (response) => {
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File edited successfully",
            title: "Success",
          })
        );
        // Refetches the file
        dispatch(loadFile(data.file_id));
        // Refetches the dashboard table data and app notifications
        dispatch(loadAppNotification());
        dispatch(loadDashboardData());
        // 
        const selectedCategoryId = localStorage.getItem("selectedCategoryId")
        if (selectedCategoryId) {
          dispatch(loadFileCategoyDetail())
          dispatch(loadFilesInCategory(selectedCategoryId, 20));
        }

      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to edit file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function editFileNameValue(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.editFileName(data).then(
      (response) => {
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File name edited successfully",
            title: "Success",
          })
        );
        dispatch(loadFile(data.file_id));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to edit file name",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}
// Update File Category
export function updateFileCategory(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.updateFileCategory(data).then(
      (response) => {
        dispatch(isRequestLoading(false));
        dispatch(loadFile(data.file_id));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File category updated successfully!",
            title: "Success",
          })
        );
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to edit file name",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadUnindexedFiles() {
  return (dispatch) => {
    dispatch(isUnindexedLoading(true));
    return axiosService.File.unIndexedFiles().then(
      (response) => {
        dispatch(isUnindexedLoading(false));
        dispatch(onUnindexedFileLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load unindexed files",
          })
        );
        dispatch(isUnindexedLoading(false));
      }
    );
  };
}

export function indexFilesToCategory(data, setIsSubmitIndexedFileLoading, handleOnFileIndexingSuccess) {
  return (dispatch) => {
    setIsSubmitIndexedFileLoading(true);
    return axiosService.File.indexFiles(data).then(
      (response) => {
        // Set loading to false and throw success message
        setIsSubmitIndexedFileLoading(false);
        handleThrowSuccess(dispatch, "File indexed successfully");
        // Refetch unindexed files
        dispatch(loadUnindexedFiles());
        // Call the handleOnFileIndexingSuccess function if it exists
        handleOnFileIndexingSuccess && handleOnFileIndexingSuccess();
      },
      (error) => {
        // Throw error message and set loading to false
        handleThrowError(dispatch, error || "Failed to index file");
        setIsSubmitIndexedFileLoading(false);
      }
    );
  };
}



// Handling the cancel of an unindexed file
export function cancelSelectedUnindexedFile(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.File.cancelUnindexedFile(data).then(
      (response) => {
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File cancelled successfully",
            title: "Success",
          })
        );
        // Refetch unindexed files
        dispatch(loadUnindexedFiles());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to cancel file",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Assigned To Me Files
export function loadAssignedToMeFiles(quantity, page = 1) {
  return (dispatch) => {
    dispatch(isSpecialFilesLoading(true));
    return axiosService.File.assignedToMeFiles(quantity, page).then(
      (response) => {
        dispatch(isSpecialFilesLoading(false));
        dispatch(onAssignedToMeFileLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load assigned to me files",
          })
        );
        dispatch(isSpecialFilesLoading(false));
      }
    );
  };
};

// Checked Out Files
export function loadCheckedOutFiles(quantity, page = 1) {
  return (dispatch) => {
    dispatch(isSpecialFilesLoading(true));
    return axiosService.File.checkedOutFiles(quantity, page).then(
      (response) => {
        dispatch(isSpecialFilesLoading(false));
        dispatch(onCheckedOutFileLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load checked out files",
          })
        );
        dispatch(isSpecialFilesLoading(false));
      }
    );
  };
}

// Favourite Files
export function loadFavouriteFiles(quantity, page = 1) {
  return (dispatch) => {
    dispatch(isSpecialFilesLoading(true));
    return axiosService.File.favouriteFiles(quantity, page).then(
      (response) => {
        dispatch(isSpecialFilesLoading(false));
        dispatch(onFavouritesFileLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load favourite files",
          })
        );
        dispatch(isSpecialFilesLoading(false));
      }
    );
  };
}

// File Version History
export function loadFileVersionHistory(id) {
  return (dispatch) => {
    dispatch(isFileSettingsLoading(true));
    return axiosService.File.loadFileVersionHistory(id).then(
      (response) => {
        dispatch(isFileSettingsLoading(false));
        dispatch(onFileVersionHistoryLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file version history",
          })
        );
        dispatch(isFileSettingsLoading(false));
      }
    );
  };
}

//Load  File Permission
export function loadFilePermissions(id) {
  return (dispatch) => {
    dispatch(isFileSettingsLoading(true));
    return axiosService.File.loadFilePermissions(id).then(
      (response) => {
        dispatch(isFileSettingsLoading(false));
        dispatch(onFilePermissionsLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load file permissions",
          })
        );
        dispatch(isFileSettingsLoading(false));
      }
    );
  };
}

// Update File Permission
export function updateFilePermission(data) {
  return (dispatch) => {
    dispatch(isFileSettingsLoading(true));

    // Clear any popup message(success || error) currently being displayed
    dispatch(messageCleared());
    return axiosService.File.updateFilePermission(data).then(
      (response) => {
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "File permission updated successfully!",
            title: "Success",
          })
        );
        // Refetch file permission to make update
        dispatch(loadFilePermissions(data.file_id));
        dispatch(isFileSettingsLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to update file permission",
          })
        );
        dispatch(isFileSettingsLoading(false));
      }
    );
  };
}

// Share File
export function shareFile(fileId, data, shareLink, setEncryptedShareLink) {
  return (dispatch) => {
    // Clear any popup message(success || error) currently being displayed
    dispatch(isFileSettingsLoading(true));
    dispatch(messageCleared());
    return axiosService.File.shareFile(fileId, data).then(
      (response) => {
        setEncryptedShareLink(shareLink);
        dispatch(isFileSettingsLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to share link!",
          })
        );
        dispatch(isFileSettingsLoading(false));
      }
    );
  };
}

// Get workflow and stage
export function getWorkflowAndStage(fileId) {
  return (dispatch) => {
    dispatch(messageCleared());
    return axiosService.File.loadWorkflowAndStage(fileId).then(
      (response) => {
        dispatch(onWorkflowAndStageLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load workflow and stage!",
          })
        );
      }
    );
  };
}


// Get next Incremental value for metadata
export function getNextIncrementalValueForMetadata(metadataId) {
  return (dispatch) => {
    return axiosService.File.loadIncrementalValue(metadataId).then(
      (response) => {
        dispatch(onIncrementalValueLoad(response));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load incremental value!",
          })
        );
      }
    );
  };
}

