// import { scrollToTop } from "../store/modules/notification";
import { OnLogout } from "store/modules/auth";
import axios from "axios";
import store from "../../src/store";
import { appGoesOffline } from "store/modules/error";

//DEVELOPMENT
export const API_ROOT = process.env.REACT_APP_BASE_URL;
export const CLIENT_URL = "http://localhost:3000";


export const instance = axios.create({
  baseURL: API_ROOT,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
  // headers: { "Content-Type": "multipart/form-data" },
  headers: { "Content-Type": "application/json" },
});

export const IMAGE_URL = API_ROOT + "/account/uploads/";

let token = null;
// const responseBody = (res) => res.body;

const getAuthToken = () => {
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  const token = auth ? auth?.data?.access_token : null;
  return token;
};

export const tokenPlugin = (req) => {
  const { dispatch } = store();
  token = getAuthToken();
  req.set("Accept", "application/json");
  req.on("response", function (res) {
    if (res.status === 401) {
      dispatch(OnLogout());
    }

    if (res.status === (201 || 200)) {
      // scrollToTop();
    }
  });
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  }

  req.on("error", (error) => {
    if (error.status === undefined) {
      dispatch(appGoesOffline());

      return;
    }
  });
};

const requests = {
  delete: (url) =>
    instance
      .delete(`${API_ROOT}${url}`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      })
      .then((responseBody) => {
        return responseBody;
      }),
  get: async (url, config = null) =>
    instance
      .get(`${API_ROOT}${url}`, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
        ...config,
      })
      .then((responseBody) => {
        return responseBody;
      }),
  getWithBody: (url, body, config = null) => {
    return instance.get(`${API_ROOT}${url}`, body, {
      headers: { Authorization: `Bearer ${getAuthToken()}` },
      ...config,
    }).then((responseBody) => {
      return responseBody;
    })
  },
  put: (url, body, config = null) => {
    return instance
      .put(`${API_ROOT}${url}`, body, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
        ...config,
      })
      .then((responseBody) => {
        return responseBody;
      });
  },
  patch: (url, body, config = null) => {
    return instance
      .patch(`${API_ROOT}${url}`, body, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
        ...config,
      })
      .then((responseBody) => {
        return responseBody;
      });
  },

  post: (url, body, config = null) => {
    return instance
      .post(`${API_ROOT}${url}`, body, {
        headers: { Authorization: `Bearer ${getAuthToken()}` },
        ...config,
      })
      .then((responseBody) => {
        return responseBody;
      });
  },
};

const Auth = {
  logError: (data) => requests.post("/v1/log/error", { data }),
  isAuth: () => {
    const token = getAuthToken();
    return !!token;
  },
  setToken: () => {
    const auth = JSON.parse(window.localStorage.getItem("auth"));
    token = auth.token;
  },
  saveAuthData: (_user) => {
    window.localStorage.setItem("auth", JSON.stringify(_user));
    token = _user.data.access_token;
  },
  saveTemporalAuthData: (_user) => {
    localStorage.setItem("temporal_auth", JSON.stringify(_user));
  },
  clearTemporalAuthData: () => {
    window.localStorage.removeItem("auth");
  },
  saveFirstTime: () => {
    window.localStorage.setItem("ftime", true);
  },
  loggedInOnce: () => {
    return !!window.localStorage.getItem("ftime");
  },
  logout: () => {
    // Remove the token from the local storage
    localStorage.removeItem("auth");
    // Remove the 2FA session info from the local storage
    localStorage.removeItem("isCompany2FAEnabled");
    localStorage.removeItem("isUser2FAVerified");
    // Remove the token from the axios instance
    token = null;
  },
  current: () => JSON.parse(window.localStorage.getItem("auth")),
  currentUserDetails: () => {
    const user = window.localStorage.getItem("userData");
    return JSON.parse(user);
  },
  isAdmin: () => {
    const user = Auth.current();
    return user?.accountType === ("Admin" || "Developer");
  },
  logoutUser: () => requests.post("/api/logout"),
  login: (email, password) => requests.post("/api/login", { email, password }),
  register: (data) => requests.post("/account/register", data),
  checkSession: () => requests.get("/api/check-session"),
  checkValidEmail: (email) =>
    requests.post(`/account/email/is-valid`, { email }),
  forgotPassword: (email) => requests.get(`/account/forgot-password/${email}`),
  userDetial: () => requests.get(`/user/current/`),
  resendEmail: (email) => requests.get(`/account/resend-verification/${email}`),
  updatePassword: (data) => requests.put(`/account/updatepassword`, data),
  resetPassword: (email, password, token) =>
    requests.post(`/account/reset-password`, { email, password, token }),
  sendResetToken: (email) =>
    requests.post(`/account/password/email`, { email }),
  verifyResetToken: (email, token) =>
    requests.post(`/account/password/verify-token`, { email, token }),
  watchLogout: () => requests.post("api/logout"),
  // 2FA
  is2FAEnabled: () => requests.post(`/api/users/is2faenabled`),
  enable2FA: () => requests.post(`/api/users/enable2fa`),
  verify2FA: (data) => requests.post(`/api/users/verify2fa`, data),
  disable2FA: () => requests.post(`/api/users/disable2fa`),
  clear2FASession: (data) => requests.post(`/api/users/clear2fa`, data),
};


const User = {
  signedUserDetail: () => requests.get(`/api/user/current`),
  updatePassword: (data) => requests.post(`/api/update-password`, data),
  uploadSignature: (data) => requests.post(`/api/upload-user-signature`, data),
  loadUsers: () => requests.get(`/api/all_user`),
  // Admin
  loadLoggedInUsers: () => requests.get(`/api/logged-in-user`),
  createUser: (data) => requests.post(`/api/users/createUser`, data),
  createManyUsers: (data) => requests.post(`/api/users/create-many-users`, data),
  importUsers: (data) => requests.post(`/api/import_user`, data),
  editUser: (userId, data) => requests.put(`/api/editUser/${userId}`, data),
  activateUser: (data) => requests.put(`/api/activate_user`, data),
  deactivateUser: (data) => requests.put(`/api/deactivate_user`, data),
  deleteUser: (data) => requests.put(`/api/delete_user`, data),
  resetPassword: (data) => requests.post(`/api/reset-password`, data),
  getAllUsers: (quantity, page) => requests.get(`/api/users/all?page=${page}&quantity=${quantity}`)


};

const Group = {
  loadGroups: () => requests.get(`/api/all_group`),
  createGroup: (data) => requests.post(`/api/create_group`, data),
  editGroup: (id, data) => requests.post(`/api/edit_group/${id}`, data),
  deleteGroup: (id) => requests.post(`/api/group/delete/${id}`)
};

const Setup = {
  load: () => requests.get("/api/get_logo_and_expiry_date"),
  // Admin
  loadCompanyDetails: () => requests.get("/api/company_details"),
  uploadCompanyLogo: (formData) => requests.post("/api/controlpanel/upload-logo", formData),
  uploadCompanyBackground: (formData) => requests.post("/api/controlpanel/upload-background", formData)
};

const Category = {
  load: () => requests.get("/api/folders/workflow"),
};


const Workflow = {
  initiateWorkflow: (data) => requests.post("/api/initiate-workflow", data),
  getWorkflowOnly: () => requests.get(`/api/workflows`),
  getAllWorkflows: () => requests.get(`/api/workflow/index`),
  getWorkflowById: (id) => requests.get(`/api/view_workflow/${id}`),
  getWorkflowStep: (id) => requests.get(`/api/get_step/${id}`),
  saveWorkflow: (data) => requests.post("/api/create_and_update_workflow", data),
  deleteWorkflow: (id) => requests.delete(`/api/del_workflow/${id}`),
  // Steps || State
  saveWorkflowStep: (data) => requests.post(`/api/create_update_workflow_step`, data),
  deleteWorkflowStep: (id) => requests.delete(`/api/workflow/del_step/${id}`),
  // Workflow Metadata
  saveWorkflowMetadata: (data) => requests.post(`/api/workflow/create_update_metadata`, data),
  // Notification
  getNotificationRecipient: () => requests.get(`/api/get_recipient`),
  getNotifications: (stepId) => requests.get(`/api/get_notification/${stepId}`),
  updateNotification: (data) => requests.post(`/api/update_notification`, data),
  deleteNotification: (notificationId) => requests.delete(`/api/del_notification/${notificationId}`),
  // Conditions
  getPreCondition: (data) => requests.post(`/api/get_condition`, data),
  getPostCondition: (data) => requests.post(`/api/get_condition_post`, data),
  updateCondition: (data) => requests.post(`/api/update_condition`, data),
  deleteCondition: (conditionId) => requests.delete(`/api/del_condition/${conditionId}`),
  // Triggers
  getTriggers: (stepId) => requests.get(`/api/get_trigger/${stepId}`,),
  updateTrigger: (data) => requests.post(`/api/update_trigger`, data),
  deleteTrigger: (triggerId) => requests.delete(`/api/del_trigger/${triggerId}`),
  // Owners|Users
  getAssignees: (stepId) => requests.get(`/api/get_assignee/${stepId}`),
  updateAssignee: (data) => requests.post(`/api/update_assignee`, data),
  deleteAssignee: (assigneeId) => requests.delete(`/api/del_assignee/${assigneeId}`),
  // Metadata
  getStepMetadata: (stepId) => requests.get(`/api/get_step_metadata/${stepId}`),
  updateStepMetadata: (data) => requests.post(`/api/workflow/step/edit_metadata`, data),
  // Metadata Default Value
  setDefaultValueForWorflowStepMetadata: (data) => requests.post(`/api/workflow/step/set_default_metadata`, data),
}

const Dashboard = {
  load: (page = 1) => requests.get(`/api/dashboard/table?page=${page}`),
};

const Search = {
  load: (searchVal, quantity = 100, page = 1,) => requests.get(`/api/search-files?search=${searchVal}&page=${page}&quantity=${quantity}`),
  loadAllSearchedFiles: (searchVal, quantity = 100, page = 1,) => requests.get(`/api/all-search-files?search=${searchVal}&page=${page}&quantity=${quantity}`),
};

const Filter = {
  getFilterAttribute: () => requests.get("/api/filter-attributes"),
  loadFilterData: (data, quantity, page) => requests.post(`/api/filter-files?page=${page}&quantity=${quantity}`, data)
}

const Notification = {
  load: () => requests.get("/api/get-notification"),
  markAsRead: (id) => requests.post(`/api/mark-notification-as-read/${id}`),
  markAllAsRead: () => requests.post(`/api/clear-notifications`),
};



const File = {
  loadFileDetail: (id) => requests.get(`/api/file-view/${id}`),
  loadFileDetailPublicly: (id) => requests.get(`/api/public/file-view/${id}`),
  uploadOtherFile: (data) => requests.post("/api/other-files-upload", data),
  uploadFile: (data, config) => requests.post("/api/new-upload", data, config),
  editFileMetadata: (data) => requests.post("/api/edit-file-user-field", data),
  editFileName: (data) => requests.post("/api/edit-file-name", data),
  downloadSelectedFiles: (data) => requests.post("/api/download-many-other-files", data),
  updateFileCategory: (data) => requests.post("/api/update-file-category", data),
  unIndexedFiles: () => requests.get("/api/unindex-file"),
  indexFiles: (data) => requests.post("/api/submitmetas", data),
  cancelUnindexedFile: (data) => requests.post("/api/cancel-index-file", data),
  assignedToMeFiles: (quantity, page) => requests.get(`/api/assigned-files?page=${page}&quantity=${quantity}`),
  checkedOutFiles: (quantity, page) => requests.get(`/api/checked-out-files?page=${page}&quantity=${quantity}`),
  favouriteFiles: (quantity, page) => requests.get(`/api/favorite-files?page=${page}&quantity=${quantity}`),
  loadFileVersionHistory: (id) => requests.get(`/api/version-preview/${id}`),
  loadFilePermissions: (id) => requests.get(`/api/file-permissions/${id}`),
  updateFilePermission: (data) => requests.post(`/api/update-file-permission`, data),
  shareFile: (fileId, data) => requests.post(`/api/share-file/${fileId}`, data),
  loadWorkflowAndStage: (fileId) => requests.get(`/api/folder-workflow-stage/${fileId}`),
  loadIncrementalValue: (metadataId) => requests.get(`/api/incremental-value/${metadataId}`)
};

const FileCategory = {
  loadFileCategories: () => requests.get("/api/folders"),
  loadCategoryMetadata: (id) => requests.get(`/api/folderMeta/${id}`),
  loadAllFileCategories: () => requests.get("/api/admin/fileCategories"), // WITHOUT PERMISSION RESTRICTIONS
  loadFileCategoriesDetails: () => requests.get("/api/fileCategories"),
  loadFilesInCategory: (id, quantity, page) =>
    requests.get(`/api/fileIncategory/${id}?page=${page}&quantity=${quantity}`),
  loadFolderMetadata: (id) => requests.get(`/api/folderMeta/${id}`),
  filterFileCategory: (id, data) =>
    requests.post(`/api/fileIncategory/${id}`, data),
  // Category Groups
  loadCategoryGroups: () => requests.get("/api/category-groups"),
  loadACategoryGroup: (groupId) => requests.get(`/api/category-groups/${groupId}`),
  createCategoryGroup: (data) =>
    requests.post(`/api/create/category-group`, data),
  updateCategoryGroup: (data) =>
    requests.post(`/api/update/category-group`, data),
  // Categories and Sub categories
  loadCategoryInfo: (folderId) => requests.get(`/api/folder/info/${folderId}`),
  createCategoriesOrSubCategories: (data) => requests.post("/api/storeFolder", data),
  updateCategoriesOrSubCategories: (data) => requests.post("/api/update/folder", data),
  duplicateCategoriesOrSubCategories: (data) => requests.post("/api/duplicate/folder", data),
  deleteCategoriesOrSubCategories: (data) => requests.post("/api/deleteFolder", data),
  // Related Files tags
  loadRelatedFileTagsForCategory: (id) => requests.get(`/api/related-tags/${id}`),
  createRelatedFileTagsForCategory: (data) => requests.post(`/api/add-related-tags`, data),
  updateRelatedFileTagsForCategory: (data) => requests.post(`/api/edit-related-tag`, data),
  deleteRelatedFileTagsForCategory: (id) => requests.post(`/api/delete-related-tag/${id}`),
};

const FileAction = {
  uploadFileComment: (data) => requests.post("/api/file-comment", data),
  deleteOtherFiles: (data) => requests.post("/api/delete-otherfiles", data),
  downloadFile: (id) => requests.get(`/api/download-files/${id}`),
  deleteFile: (data) => requests.post("/api/delete", data),
  downloadOtherFile: (id) => requests.get(`/api/download-otherfiles/${id}`),
  followFile: (data) => requests.post("/api/follow", data),
  unfollowFile: (data) => requests.post("/api/unfollow", data),
  favouriteFile: (data) => requests.post("/api/favourite-file", data),
  unFavouriteFile: (data) => requests.post("/api/unfavourite-file", data),
  rollBackFile: (data) => requests.post("/api/rollback-version", data),
  lockUnlockFile: (data) => requests.post("/api/lock-file", data),
  checkInFile: (data) => requests.post("/api/check-in", data),
  checkOutFile: (data) => requests.post("/api/check-out", data),
  manuallyTriggerWorkflowStep: (data) => requests.post("/api/manual-triger", data),
  exportFile: (fileId) => requests.get(`/api/export-files-main/${fileId}`),
};

const ValueList = {
  retrieveValueList: () => requests.get(`/api/valuelist`),
  retrieveSingleValueList: (id) => requests.get(`/api/valuelist/${id}`),
  retrieveValueListItems: (id) => requests.get(`/api/valuelist-items/${id}`),
  createValueListFromLocal: (data) => requests.post(`/api/create_value_list`, data),
  updateValueListFromLocal: (valuelistId, data) => requests.put(`/api/edit_value_list/${valuelistId}`, data),
  createValueListFromExcel: (data) => requests.post(`/api/create_value_list_from_excel`, data),
  updateValueListFromExcel: (valuelistId, data) => requests.put(`/api/edit_value_list_from_excel/${valuelistId}`, data),
  deleteValueList: (valuelistId) => requests.delete(`/api/destroyValueList/${valuelistId}`),

};

const Metadata = {
  retrieveAllMetadataFields: () => requests.get(`/api/user_fields`),
  retrieveAllUsersMetadataFields: () => requests.get(`/api/user_fields/users`),
  storeMetadataField: (data) => requests.post(`/api/store_user_field`, data),
  deleteMetadataField: (id) => requests.delete(`/api/deleteUserField/${id}`),
};

const AuditTrail = {
  getAuditTrails: (user_id, type, dateRange, searchParam, quantity, page) => requests.get(`/api/audits/activity?page=${page}&quantity=${quantity}${user_id ? "&user_id=" : ""}${user_id}${type ? "&type=" : ""}${type}${dateRange ? "&dateRange=" : ""}${dateRange}${searchParam ? "&search=" : ""}${searchParam}`),
};

const EmailNotification = {
  getEmailSetup: () => requests.get(`/api/email_setup`),
  getAllEmailNotifications: (quantity, page) => requests.get(`/api/email/notification?page=${page}&quantity=${quantity}`),
  runNotificationTest: (data) => requests.post(`/api/email/test`, data),
  setupEmailNotification: (data) => requests.post(`/api/email/setup`, data),
  deleteEmailNotification: (notifId) => requests.post(`/api/email/job/delete/${notifId}`),
};

const BackUp = {
  getAllBackUp: () => requests.get(`/api/backup-files`),
  getAllBackedUpFiles: () => requests.get(`/api/backup`),
  runBackupImmediately: (data) => requests.post(`/api/create_backup`, data),
  scheduleBackup: (data) => requests.post(`/api/schedule_backup`, data),
  deleteBackup: (backupId) => requests.post(`/api/delete_backup/${backupId}`),
  deleteBackedUpFile: (backupId) => requests.post(`/api/delete_backup_file/${backupId}`),
  downloadBackedUpFile: (backupId) => requests.post(`/api/download_backup_file/${backupId}`),
  uploadBackup: (data) => requests.post(`/api/upload_backup`, data),
  restoreBackup: (backupId) => requests.post(`/api/restore_backup/${backupId}`),
};

const Settings = {
  getAllAuthentications: () => requests.get(`/api/settings/authentication`),
  createAuthentication: (data) => requests.post(`/api/settings/authentication/create`, data),
  testAuthentication: (data) => requests.post(`/api/settings/authentication/test`, data),
  deleteAuthentication: (data) => requests.post(`/api/settings/authentication/delete`, data),
};

const Trash = {
  getAllTrashFiles: (quantity, page) => requests.get(`/api/files/trash?page=${page}&quantity=${quantity}`),
  retrieveTrashedFileDetail: (fileId) => requests.get(`/api/files/trash-file-view/${fileId}`),
  restoreTrashedFile: (fileId) => requests.post(`/api/files/restore-trash-file/${fileId}`),
  deleteTrashedFile: (fileId) => requests.delete(`/api/files/trash-file/${fileId}`),
};


const LicenseManagement = {
  getLicenseManagementData: () => requests.get(`/api/controlpanel/license-management`),
  updateLicenseManagement: (data) => requests.post(`/api/controlpanel/license-management`, data),


}

export default {
  Auth,
  Setup,
  Category,
  Workflow,
  User,
  Dashboard,
  Search,
  Filter,
  Group,
  Notification,
  File,
  FileCategory,
  FileAction,
  ValueList,
  Metadata,
  AuditTrail,
  EmailNotification,
  BackUp,
  Settings,
  Trash,
  LicenseManagement,

  setToken: (_token) => {
    token = _token;
  },
};
