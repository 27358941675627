import { useDispatch, useSelector } from "react-redux";
import { Error, Info, Success, Warning } from "utils/alerts";
import {
  clearMessage
} from "../store/modules/notification";
import "./AppAlert.css";

const AppAlert = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);


  const clearError = () => {
    dispatch(clearMessage());
  };

  return (
    <>
      <MainAlert notification={notification} clearError={clearError} />
    </>
  );
};

export default AppAlert;

const MainAlert = ({ notification, clearError }) => {
  if (notification?.success) {
    // toast.success(notification?.success,
    //   { className: "bg-success" });
    return <Success message={notification?.success} />
  }
  else if (notification?.warning) {
    // toast.warn(notification?.warning,
    //   { className: "bg-warning" });
    return <Warning message={notification?.warning} />
  }
  else if (notification?.error) {
    // toast.error(notification?.error,
    //   { className: "bg-danger" });
    return <Error message={notification?.error} />
  }
  else {
    // if (notification.info) {
    // toast.info(notification.error,
    //   { className: "bg-primary" });
    return <Info message={notification?.info} />
    // }
  }

  // return <ToastContainer />;
};
