import axiosService from "services/axios.service";
import { showErrorMessage, } from "./notification";


//initial values
const Initial_State = {
    auditTrails: {},
    loading: false,
    error: false,

};

// Action types
const AUDIT_TRAILS = "AUDIT_TRAILS";
const LOADING = "LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case AUDIT_TRAILS:
            return {
                ...state,
                error: null,
                auditTrails: action?.payload?.data?.data,
            };
        case LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Load Audit trails
export function onLoadAuditTrails(data) {
    return {
        type: AUDIT_TRAILS,
        payload: data,
    };
}



// Actions
export function getAuditTrails(user_id = "", type = "", dateRange = "", searchParam = "", quantity = 20, page = 1,) {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.AuditTrail.getAuditTrails(user_id, type, dateRange, searchParam, quantity, page,).then(
            (response) => {
                dispatch(onLoadAuditTrails(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load audit trails",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}

