import { showErrorMessage, showSuccessMessage } from "./notification";
import axiosService from "services/axios.service";
import { MESSAGE_TYPE } from "store/constant";

// initial values
const Initial_State = {
  noti: {},
  loading: false,
  error: false,
};

// Action types
const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
const LOADING = "LOADING";
const ERROR = "ERROR";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case LOAD_NOTIFICATIONS:
      return {
        ...state,
        error: null,
        loading: false,
        noti: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

export function onLoadNotification(data) {
  return {
    type: LOAD_NOTIFICATIONS,
    payload: data,
  };
}

export function isRequestLoading(data) {
  return {
    type: LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}

export function loadAppNotification() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Notification.load().then(
      (response) => {
        //handle success
        dispatch(onLoadNotification(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load notification",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}


// Action to mark notification as Read
export function markNotificationAsRead(data) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Notification.markAsRead(data).then(
      (response) => {
        dispatch(isRequestLoading(false));
        // Reload the notifications
        dispatch(loadAppNotification())
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to mark notification as read!",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

// Action to mark all notifications as Read
export function markAllNotificationsAsRead() {
  return (dispatch) => {
    // dispatch(messageCleared())
    dispatch(isRequestLoading(true));
    return axiosService.Notification.markAllAsRead().then(
      (response) => {
        // Reload the notifications
        dispatch(loadAppNotification())
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "All Notifications marked as read!",
            title: "Success",
          })
        );
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to mark all notifications as read!",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}