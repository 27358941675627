import { showErrorMessage } from "./notification";
import axiosService from "services/axios.service";

// initial values
const Initial_State = {
  dashboardData: {},
  previewed: {},
  checkedout: {},
  recent: {},
  assigned: {},
  loading: false,
  error: false,
};

// Action types
const LOAD_DASHBOARD_DATA = "LOAD_DASHBOARD_DATA";
const LOADING = "LOADING";
const ERROR = "ERROR";
const LOAD_PREVIEWED_DATA = "LOAD_PREVIEWED_DATA";
const LOAD_CHECKEDOUT_DATA = "LOAD_CHECKEDOUT_DATA";
const LOAD_RECENT_DATA = "LOAD_RECENT_DATA";
const LOAD_ASSIGNED_DATA = "LOAD_ASSIGNED_DATA";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case LOAD_DASHBOARD_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        dashboardData: action?.payload?.data,
      };
    case LOAD_PREVIEWED_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        previewed: action?.payload?.data?.data?.previewed,
      };
    case LOAD_CHECKEDOUT_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        checkedout: action?.payload?.data?.data?.checkedout,
      };
    case LOAD_RECENT_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        recent: action?.payload?.data?.data?.recent,
      };
    case LOAD_ASSIGNED_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        assigned: action?.payload?.data?.data?.assigned,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

export function onLoadDashboardData(data) {
  return {
    type: LOAD_DASHBOARD_DATA,
    payload: data,
  };
}

export function onLoadPreviewedData(data) {
  return {
    type: LOAD_PREVIEWED_DATA,
    payload: data,
  };
}

export function onLoadCheckedoutData(data) {
  return {
    type: LOAD_CHECKEDOUT_DATA,
    payload: data,
  };
}

export function onLoadRecentData(data) {
  return {
    type: LOAD_RECENT_DATA,
    payload: data,
  };
}

export function onLoadAssignedData(data) {
  return {
    type: LOAD_ASSIGNED_DATA,
    payload: data,
  };
}

export function isRequestLoading(data) {
  return {
    type: LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}

export function loadDashboardData() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Dashboard.load().then(
      (response) => {
        //handle success
        dispatch(onLoadDashboardData(response));
        dispatch(onLoadPreviewedData(response));
        dispatch(onLoadCheckedoutData(response));
        dispatch(onLoadRecentData(response));
        dispatch(onLoadAssignedData(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load Dashboard data",
          })
        );
        dispatch(isRequestLoading(false));
      }
    )
  };
}

export function loadPreviewedData(_, page) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Dashboard.load(page).then(
      (response) => {
        //handle success
        dispatch(onLoadPreviewedData(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load Dashboard data",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadCheckedoutData(_, page) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Dashboard.load(page).then(
      (response) => {
        //handle success
        dispatch(onLoadCheckedoutData(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load Dashboard data",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadRecentData(_, page) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Dashboard.load(page).then(
      (response) => {
        //handle success
        dispatch(onLoadRecentData(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load Dashboard data",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function loadAssignedData(_, page) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Dashboard.load(page).then(
      (response) => {
        //handle success
        dispatch(onLoadAssignedData(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load Dashboard data",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}
