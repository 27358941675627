export const binaryToPDFConverter = (binaryData, title) => {
  // Assuming binaryData is the binary data you have
  //   const binaryData = "PK..."; // Replace with your actual binary data

  // Convert binary data to a Blob
  const blob = new Blob([binaryData], { type: "application/pdf" });

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = title + ".pdf" || "converted.pdf";

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the click event on the link
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
};
