import axiosService from "services/axios.service";
import { showErrorMessage, showSuccessMessage, } from "./notification";


//initial values
const Initial_State = {
    emailSetup: null,
    emailNotifications: [],
    fetchLoading: false,
    actionLoading: false,
    error: false,

};

// Action types
const NOTIFICATIONS_DATA = "NOTIFICATIONS_DATA";
const EMAIL_SETUP_DATA = "EMAIL_SETUP_DATA";
const FETCH_LOADING = "FETCH_LOADING";
const ACTION_LOADING = "ACTION_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case NOTIFICATIONS_DATA:
            return {
                ...state,
                error: null,
                emailNotifications: action?.payload?.data?.data,
            };
        case EMAIL_SETUP_DATA:
            return {
                ...state,
                error: null,
                emailSetup: action?.payload?.data,
            };
        case FETCH_LOADING:
            return {
                ...state,
                fetchLoading: action.payload,
                error: null,
            };
        case ACTION_LOADING:
            return {
                ...state,
                actionLoading: action.payload,
                error: null,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isFetchLoading(data) {
    return {
        type: FETCH_LOADING,
        payload: data,
    };
}

export function isActionLoading(data) {
    return {
        type: ACTION_LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Fetch email notifications
export function onLoadEmailNotifications(data) {
    return {
        type: NOTIFICATIONS_DATA,
        payload: data,
    };
}

// Fetch email setup
export function onLoadEmailSetup(data) {
    return {
        type: EMAIL_SETUP_DATA,
        payload: data,
    };
}


// Actions
export function getAllEmailNotifications(quantity = 20, page = 1) {
    return (dispatch) => {
        dispatch(isFetchLoading(true));
        return axiosService.EmailNotification.getAllEmailNotifications(quantity, page).then(
            (response) => {
                dispatch(onLoadEmailNotifications(response));
                dispatch(isFetchLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load email notifications",
                    })
                );
                dispatch(isFetchLoading(false));
            }
        );
    };
}
export function getEmailSetup() {
    return (dispatch) => {
        dispatch(isFetchLoading(true));
        return axiosService.EmailNotification.getEmailSetup().then(
            (response) => {
                dispatch(onLoadEmailSetup(response));
                dispatch(isFetchLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load email setup",
                    })
                );
                dispatch(isFetchLoading(false));
            }
        );
    };
}

// Run Test for email notifications
export function runNotificationTest(data) {
    return (dispatch) => {
        dispatch(isActionLoading(true));
        return axiosService.EmailNotification.runNotificationTest(data).then(
            (response) => {
                dispatch(isActionLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Test ran on email notification successfully",
                        title: "Success",
                    })
                );
                // dispatch(getAllGroups());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to run test",
                    })
                );
                dispatch(isActionLoading(false));
            }
        );
    };
}

// Setup or send email notifications
export function setupOrSaveEmailNotifications(data) {
    return (dispatch) => {
        dispatch(isActionLoading(true));
        return axiosService.EmailNotification.setupEmailNotification(data).then(
            (response) => {
                dispatch(isActionLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Email settings updated successfully",
                        title: "Success",
                    })
                );
                // refectch all email notifications
                dispatch(getAllEmailNotifications());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to save email notification",
                    })
                );
                dispatch(isActionLoading(false));
            }
        );
    };
}

// Delete email notifications
export function deleteEmailNotification(notifId, handleEmptyInputStates) {
    return (dispatch) => {
        dispatch(isActionLoading(true));
        return axiosService.EmailNotification.deleteEmailNotification(notifId).then(
            (response) => {
                dispatch(isActionLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Email notification deleted successfully",
                        title: "Success",
                    })
                );
                // refectch all email notifications
                dispatch(getAllEmailNotifications());
                handleEmptyInputStates && handleEmptyInputStates()
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to deletr email notification",
                    })
                );
                dispatch(isActionLoading(false));
            }
        );
    };
}