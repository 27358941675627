import axiosService from "services/axios.service";
import { showErrorMessage, showSuccessMessage, } from "./notification";


//initial values
const Initial_State = {
    authentications: [],
    loading: false,
    error: false,
};

// Action types
const GET_AUTHENTICATIONS = "GET_AUTHENTICATIONS";
const AUTH_LOADING = "LOADING";
const SAVE_AUTH_LOADING = "SAVE_AUTH_LOADING";
const DELETE_AUTH_LOADING = "DELETE_AUTH_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case GET_AUTHENTICATIONS:
            return {
                ...state,
                error: null,
                loading: false,
                authentications: action?.payload?.data,
            };
        case AUTH_LOADING:
            return {
                ...state,
                loading: action.payload,
                error: null,
            };
        case SAVE_AUTH_LOADING:
            return {
                ...state,
                saveAuthLoading: action.payload,
            };
        case DELETE_AUTH_LOADING:
            return {
                ...state,
                deleteAuthLoading: action.payload,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: AUTH_LOADING,
        payload: data,
    };
}

export function isSaveAuthLoading(data) {
    return {
        type: SAVE_AUTH_LOADING,
        payload: data,
    };
}

export function isDeleteAuthLoading(data) {
    return {
        type: DELETE_AUTH_LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Filter Response
export function onAuthDataLoad(data) {
    return {
        type: GET_AUTHENTICATIONS,
        payload: data,
    };
}


// Actions
export function getAllAuthentications() {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Settings.getAllAuthentications().then(
            (response) => {
                dispatch(onAuthDataLoad(response));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load all authentications!",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}


// Create an Authentication
export function createAuthentication(data) {
    return (dispatch) => {
        dispatch(isSaveAuthLoading(true));
        return axiosService.Settings.createAuthentication(data).then(
            (response) => {
                dispatch(isSaveAuthLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Authentication created successfully!",
                        title: "Success",
                    })
                );
                dispatch(getAllAuthentications());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to create authentication!",
                    })
                );
                dispatch(isSaveAuthLoading(false));
            }
        );
    };
}

// Test Authentication
export function testAuthentication(data) {
    return (dispatch) => {
        dispatch(isSaveAuthLoading(true));
        return axiosService.Settings.testAuthentication(data).then(
            (response) => {
                dispatch(isSaveAuthLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Authentication tested successfully!",
                        title: "Success",
                    })
                );
                dispatch(getAllAuthentications());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to test authentication!",
                    })
                );
                dispatch(isSaveAuthLoading(false));
            }
        );
    };
}

// Delete Authentication
export function deleteAuthentication(data, setIsToClearInputStates) {
    return (dispatch) => {
        dispatch(isDeleteAuthLoading(true));
        return axiosService.Settings.deleteAuthentication(data).then(
            (response) => {
                dispatch(isDeleteAuthLoading(false));
                dispatch(
                    showSuccessMessage({
                        type: "success",
                        message: "Authentication deleted!",
                        title: "Success",
                    })
                );
                setIsToClearInputStates && setIsToClearInputStates(true)
                dispatch(getAllAuthentications());
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to delete authentication!",
                    })
                );
                dispatch(isDeleteAuthLoading(false));
            }
        );
    };
}