export const adminRoleTypeOptions = [
  { key: "General staff", value: "General Staff" },
  { key: "File Control Admin", value: "File Control Admin" },
  { key: "User Administration Admin", value: "User Administration Admin" },
  { key: "Auditor", value: "Auditor" },
  { key: "COMPANY ADMIN", value: "Company Admin" },
];


export const allRolesArray = ["File Control Admin", "User Administration Admin", "Auditor", "COMPANY ADMIN", "General Staff"]

// Actions
// General Staff - User section only

// File control admin - user section and File control only

// User Admin - user section and user admin only

// Auditor - user section, (file control and user admin (read only)

// Company admin - all

export const adminRolesAccessArr = [
  {
    roleKey: "File Control Admin",
    access: ["File Control"],
  },
  {
    roleKey: "User Administration Admin",
    access: ["Settings"],
  },
  {
    roleKey: "Auditor",
    access: ["File Control", "Settings"],
  },
  {
    roleKey: "COMPANY ADMIN",
    access: ["File Control", "Settings"],
  },
];

export const handleGetRoleNameFromKey = function (roleKey) {
  const currentRoleObject = adminRoleTypeOptions.find(
    (role) => role.key === roleKey
  );
  if (currentRoleObject) return currentRoleObject.value;
  return "";
};

export const handleGetAdminRoleAccessFromRoleKey = function (roleKey) {
  const currentRoleObject = adminRolesAccessArr.find(
    (role) => role.roleKey === roleKey
  );
  if (currentRoleObject) return currentRoleObject.access;
  return [];
};
