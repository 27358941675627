import { showErrorMessage } from "./notification";
import axiosService from "services/axios.service";

// initial values
const Initial_State = {
  userSetup: {},
  loading: false,
  error: false,
};

// Action types
const LOAD_USER_SETUP = "LOAD_USER_SETUP";
const LOADING = "LOADING";
const ERROR = "ERROR";

// Reducer
export default function reducer(state = Initial_State, action = {}) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case LOAD_USER_SETUP:
      return {
        ...state,
        error: null,
        loading: false,
        userSetup: action.payload?.data,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

// Action Creators
// export function onCreateInstantJob(data) {
//   return {
//     type: CREATE_INSTANT_JOB,
//     payload: data,
//   };
// }

export function onLoadUserSetup(data) {
  return {
    type: LOAD_USER_SETUP,
    payload: data,
  };
}

export function isRequestLoading(data) {
  return {
    type: LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: ERROR,
    payload: data,
  };
}

// Actions
// export function createInstantJob(instantjob) {
//   return (dispatch) => {
//     dispatch(isRequestLoading(true));
//     return agent.InstantJob.save(instantjob).then(
//       (response) => {
//         dispatch(isRequestLoading(false));
//         // handle success
//         dispatch(
//           showErrorMessage({
//             type: MESSAGE_TYPE.SUCCESS,
//             message: "Instant Job successful created",
//             title: "Instant job create Successful",
//           })
//         );
//         dispatch(push("/instant-hires"));
//       },
//       (error) => {
//         // handle error
//         dispatch(
//           showErrorMessage({
//             type: "error",
//             message: error,
//             title: "Failed to create Instant job",
//           })
//         );
//         dispatch(isRequestLoading(false));
//       }
//     );
//   };
// }

export function loadUserSetup() {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Setup.load().then(
      (response) => {
        //handle success
        dispatch(onLoadUserSetup(response));
        dispatch(isRequestLoading(false));
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error,
            title: "Failed to load Instant jobs",
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}
